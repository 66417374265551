import React, { Fragment, useEffect, useState } from "react";

// icons
import {
  faBuilding,
  faCubes
} from "@fortawesome/free-solid-svg-icons";

// components
import BotonBack from "../Utiles/BotonBack";
import Subtitulo from "../Utiles/Subtitulo";
import Header from "../Header/Header";
import ItemStockSucursalEliminado from "./ItemStockSucursalEliminado";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

// route
import { withRouter } from "react-router-dom";

// services
import {
  getAllStockSucursal,
} from "../../Services/stockSucursalService";
import {
  getAllSucursales
} from "../../Services/sucursalService";

// alert swal
import Swal from "sweetalert2";

const StockSucursalEliminado = ({ history, location }) => {
  let token = JSON.parse(atob(sessionStorage.getItem("token").split(".")[1]));
  let rol = token.authorities[0];
  
  const [isLoad, setIsLoad] = useState(true);
  const [stockSucursal, setStocksSucursales] = useState([]);
  const [sucursales, setSucursales] = useState([]);
  const [sucursal, setSucursal] = useState(location.state.sucursal);

  //Pagination
  const [page, setPage] = useState(0);
  const [totalRegister, setTotalRegister] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (rol !== "ROLE_ADMIN_MAGENTA") history.goBack();
    getAllSucursales(true, 0, "", 1000)
      .then(res => {
        setSucursales(res.data.content);
      })
      .catch((err) => console.log("error get sucursal"));
    getAllStockSucursal(false, 0, sucursal.id, "", "", query, 50)
      .then((res) => {
        setStocksSucursales(res.data.content);
        setTotalRegister(res.data.totalElements);
        setTotalPage(res.data.totalPages);
        setIsLoad(false);
      })
      .catch((err) => console.log("error get stock sucursal"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoad, sucursal]);

  const nextPage = () => {
    if (totalPage === 0) {
      Swal.fire({
        toast: true,
        position: "top",
        title: "No hay mas páginas",
        type: "info",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }
    if (page === totalPage - 1) {
      Swal.fire({
        toast: true,
        position: "top",
        title: "No hay mas páginas",
        type: "info",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }
    getAllStockSucursal(false, page + 1, sucursal.id, "", "", query, 50)
      .then((res) => {
        setPage(page + 1);
        setStocksSucursales(res.data.content);
        setTotalRegister(res.data.totalElements);
        setTotalPage(res.data.totalPages);
        setIsLoad(false);
      })
      .catch((err) => console.log("error"));
  };

  const previousPage = () => {
    if (page === 0) {
      Swal.fire({
        toast: true,
        position: "top",
        title: "No hay mas páginas",
        type: "info",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }
    getAllStockSucursal(false, page - 1, sucursal.id, "", "", query, 50)
      .then((res) => {
        setPage(page - 1);
        setStocksSucursales(res.data.content);
        setTotalRegister(res.data.totalElements);
        setTotalPage(res.data.totalPages);
        setIsLoad(false);
      })
      .catch((err) => console.log("error"));
  };

  const handleBuscador = (e) => {
    if (e.key === "Enter") {
      setQuery(e.target.value);
      getAllStockSucursal(false, 0, sucursal.id, "", "", e.target.value, 50)
        .then((res) => {
          setPage(0);
          setStocksSucursales(res.data.content);
          setTotalRegister(res.data.totalElements);
          setTotalPage(res.data.totalPages);
          setIsLoad(false);
        })
        .catch((err) => console.log("error"));
    }
  };

  return (
    <Fragment>
      <Header />
      <div className="container-fluid mt-3">
        <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
          <BotonBack />
          <Subtitulo icon={faCubes} title={"Stock Sucursal Eliminados"} />
        </div>

        <div className="d-flex bg-gris p-3 align-items-center justify-content-between">
          <div className="p-2 flex-grow-1 bd-highlight">
            <div className="input-group-prepend input-group-lg">
              <span className="input-group-text" id="inputGroupPrepend">
                <FontAwesomeIcon icon={faSearch} />
              </span>
              <input
                type="text"
                className="form-control"
                id="inputNombre"
                name="precio"
                placeholder="Buscar"
                onKeyPress={(e) => {
                  handleBuscador(e);
                }}
              />
            </div>
          </div>
          {rol === "ROLE_ADMIN_MAGENTA" ? (
            <div className="p-2 bd-highlight">
              <div className="dropdown">
                <button
                  className="btn btn-outline-info btn-lg dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon={faBuilding} /> {`${sucursal.nombre}`}
                </button>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownMenuButton"
                >
                  {sucursales.map((sucursal) => {
                    return (
                      <button
                        key={sucursal.id}
                        className="dropdown-item"
                        onClick={() => setSucursal(sucursal)}
                      >
                        {sucursal.nombre}
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div className="bg-gris p-3">
          <div className="d-flex flex-row bd-highlight">
            <div className="p-2 bd-highlight">
              <button
                className="btn btn-outline-info"
                onClick={() => previousPage()}
                aria-label="Previous"
              >
                <span aria-hidden="true">&laquo; Anterior</span>
              </button>
            </div>
            <div className="p-2 bd-highlight mt-1">
              Registros: {totalRegister} | Pagina actual: {page + 1} | Total
              páginas : {totalPage}
            </div>
            <div className="p-2 bd-highlight">
              <button
                className="btn btn-outline-info"
                onClick={() => nextPage()}
                aria-label="Next"
              >
                <span aria-hidden="true">Siguiente &raquo;</span>
              </button>
            </div>
          </div>

          <table className="table table-striped mt-3 text-center">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Articulo</th>
                <th scope="col">Costo</th>
                <th scope="col">Precio Final</th>
                <th scope="col">Precio Calculado</th>
                <th scope="col">Precio Final Sucursal</th>
                <th scope="col">Pesable</th>
                <th scope="col">Peso Promedio</th>
                <th scope="col">Cantidad</th>
                <th scope="col">Unidad Medida</th>
                <th scope="col">Fecha Modificación</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {stockSucursal.map((articulo) => (
                <ItemStockSucursalEliminado
                  key={parseInt(articulo.articulo.id)}
                  articulo={articulo}
                  sucursal={sucursal}
                  setIsLoad={setIsLoad}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(StockSucursalEliminado);
