import axios from 'axios';
import {url} from './global';


// PAGE VENTAS ALL SUCURSALES
export const getAllVentasPage = async (page, data) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.post(`${url}/ventas/page/${page}`, data,{headers});
    return res;
}
// END

// PAGE VENTAS BY SUCURSAL 
export const getAllVentasBySucursalPage = async (page, data) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.post(`${url}/ventas/sucursal/${page}`, data,{headers});
    return res;
}
// END

export const getByIdVentas = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/ventas/${id}`,{headers});
    return res;
}

export const editByIdVentas = async (venta) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.put(`${url}/ventas/${venta.idVenta}`, venta,{headers});
    return res;
}

export const deleteByIdVentas = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.delete(`${url}/ventas/${id}`,{headers});
    return res;
}

export const saveVentas = async (venta) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/ventas`,venta,{headers});
    return res;
}

export const getLastNroComprobante = async (idSucursal) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/ventas/ultimo-comprobante/${idSucursal}`,{headers});
    return res;
}
