import axios from 'axios';
import {url} from './global';

export const savePropietario = async (propietario) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/propietarios`,propietario,{headers});
    return res;
}

export const getByIdPropietario = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/propietarios/${id}`,{headers});
    return res;
}

export const editByIdPropietario = async (propietario) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.put(`${url}/propietarios/${propietario.idPropietario}`, propietario,{headers});
    return res;
}

export const deleteByIdPropietario = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.delete(`${url}/propietarios/${id}`,{headers});
    return res;
}

export const getAllPropietarios = async () => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/propietarios`,{headers});
    return res;
}