import React, { Fragment, useState } from 'react';
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo'
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalculator, faPlus, faPercentage } from '@fortawesome/free-solid-svg-icons'
import { withRouter } from 'react-router-dom';
import { AddListasPrecio } from '../../Actions/ListaPrecioAction'
import { saveListasPrecios } from '../../Services/listaPrecioService';
import { useForm } from 'react-hook-form';
import validationsConfig from './ValidationConfigListaPrecio';
import getErrorMessageInValidation from "../Utiles/validationsInForm";
import Header from '../Header/Header';

function AddListasPrecios({ history }) {

    const [nombre, setNombre] = useState('');
    const [porcentajeMarcacion, setPorcentajeMarcacion] = useState('');
    const dispatch = useDispatch();
    const { register, handleSubmit, errors } = useForm();

    const addListaPrecioSubmit = (e) => {
        // creamos el departamento
        let listaPrecio = {
            nombre, porcentajeMarcacion
        }

        saveListasPrecios(listaPrecio)
            .then(res => {
                dispatch(AddListasPrecio(res));
                history.goBack();
            })
            .catch(error => console.log('error al guardar'));
    }

    return (
        <Fragment>
            <Header/>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faCalculator}
                        title={'Lista de Precios'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={handleSubmit(addListaPrecioSubmit)}>
                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputNombre">Nombre</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faCalculator} />
                                    </span>
                                    <input type="text"
                                        id="inputNombre"
                                        name="nombre"
                                        className="form-control"
                                        placeholder="Ej: Lista 1"
                                        onChange={e => setNombre(e.target.value)} 
                                        ref={register(validationsConfig.nombre)}    
                                    />
                                </div>
                                <small className="form-text text-danger">
                                    {errors.nombre &&
                                    getErrorMessageInValidation(
                                        "nombre",        
                                        errors.nombre,    
                                        validationsConfig 
                                    )}
                                </small>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputPorcentajeMarcacion">Porcentaje Marcacion</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faPercentage} />
                                    </span>
                                    <input type="number"
                                        className="form-control"
                                        id="inputPorcentajeMarcacion"
                                        name="porcentajeMarcacion"
                                        placeholder="Ej. 1, 2, 100"
                                        step="any"
                                        onChange={e => setPorcentajeMarcacion(e.target.value)} 
                                        ref={register(validationsConfig.porcentajeMarcacion)}
                                    />
                                </div>
                                <small className="form-text text-danger">
                                    {errors.porcentajeMarcacion &&
                                    getErrorMessageInValidation(
                                        "porcentajeMarcacion",        
                                        errors.porcentajeMarcacion,    
                                        validationsConfig 
                                    )}
                                </small>
                            </div>
                        </div>


                        <div className="text-center">
                            <button type="submit" className="btn btn-outline-success">
                                <FontAwesomeIcon icon={faPlus} /> Crear Lista Precio</button>
                        </div>


                    </form>
                </div>

            </div>
        </Fragment>
    );
}

export default withRouter(AddListasPrecios);