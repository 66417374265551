import axios from 'axios';
import {url} from './global';


export const getAllCondicionesFiscales = async () => {
    
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    
    let res = await axios.get(`${url}/condicion-fiscal`,{headers});
    return res;
}