import React, { Fragment, useEffect, useState } from "react";

import { Modal, Button } from "react-bootstrap";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCheck, faArrowLeft, faDollarSign,
	faFileInvoiceDollar,
	faIdCard,
	faFileInvoice,
	faChevronCircleUp,
	faIdCardAlt,
	faSortAmountDown,
} from "@fortawesome/free-solid-svg-icons";

// router
import { withRouter } from "react-router-dom";

// services
import { saveVentas } from '../../../Services/ventaService';
import { saveError } from '../../../Services/errorService';
import { createTicket } from "../../../Services/apiReports";

// sweet alert
import Swal from "sweetalert2";

// css
import "./FacturaElectronicaModal.css";
import { getPuntosVenta, getTipoComprobantes, getTipoConceptos, getTipoDocumentos } from "../../../Services/apiAfipService";
import { calculoIvas, determinarPrecio, generateQRUrl, getConcepto } from "./utils";

const FacturaElectronicaModal = ({ getVentaFE, showFE, handleShowFE, handleCloseFE, handleClose, reset, isLoad }) => {

	const [venta, setVenta] = useState({});

	const [tipoDocumentos, setTipoDocumentos] = useState([]);
	const [tipoDocumento, setTipoDocumento] = useState(96);

	const [puntoVentas, setPuntoVentas] = useState([]);
	const [puntoVenta, setPuntoVenta] = useState();

	const [tipoComprobantes, setTipoComprobantes] = useState([]);
	const [tipoComprobante, setTipoComprobante] = useState(11);

	const [tipoConceptos, setTipoConceptos] = useState([]);
	const [tipoConcepto, setTipoConcepto] = useState(1);

	const [nroDocumento, setNroDocumento] = useState(1);

	useEffect(() => {
		if (isLoad) {
			getTipoDocumentos()
				.then(data => {
					setTipoDocumentos(data.data.ResultGet.DocTipo);
				});
			getPuntosVenta()
				.then(data => {
					setPuntoVentas(data.data.ResultGet.PtoVenta);
					const pt = JSON.parse(sessionStorage.getItem('data'));
					setPuntoVenta(pt.sucursal.propietario.puntoVenta)
				})
				.catch(err => {
					console.log(err);
					setPuntoVenta(1) // solo para dev
				})
			getTipoComprobantes()
				.then(data => {
					setTipoComprobantes(data.data.ResultGet.CbteTipo)
				});
			getTipoConceptos()
				.then(data => {
					setTipoConceptos(data.data.ResultGet.ConceptoTipo);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoad]);


	const handleShowFirst = () => {
		setVenta(getVentaFE())
		setNroDocumento((getVentaFE()).venta.cliente.idCliente.persona.dni)
		//TODO: Añadir logica para mostrar tipo documento correspondiente y tipo comprobante
		handleShowFE();
	}

	const finalizarVentaFE = async () => {

		const detalleIva = calculoIvas(venta.venta.items);

		const dataVentaRequest = {
			...venta,
			apiAfipRequest: {
				puntoVenta: Number(puntoVenta),
				tipoComprobante: Number(tipoComprobante),
				concepto: getConcepto(Number(tipoConcepto)),
				tipoDocumento: Number(tipoDocumento),
				documentoNumero: Number(nroDocumento),
				importeExentoIva: 0,
				importeTotalTributo: 0,
				...(tipoComprobante === 11 ? {
					importeTotal: Number(venta.venta.total), //importe total c/impuestos                            
					importeNetoNoGravado: 0, //importe neto no agravado, para factura C debe ser igual a cero              
					importeNeto: Number(venta.venta.total), //importe total neto (s/ impuestos) para factura c debe ser igual al importe total.                   
					importeExentoIva: 0, //importe exento, en factura c debe ser igual a cero                                  
					importeTotalIva: 0, //suma de todos los ivas, en factura c debe ser cero       
					importeTotalTributo: 0,  //suma del arreglo de tributos
				} : {
					importeTotal: Math.round(detalleIva.impIva + detalleIva.impNeto, 2), //importe total c/impuestos                            
					importeNetoNoGravado: 0, //importe neto no agravado, para factura C debe ser igual a cero              
					importeNeto: Math.round(detalleIva.impNeto, 2), //importe total neto (s/ impuestos) para factura c debe ser igual al importe total.                   
					importeExentoIva: 0, //importe exento, en factura c debe ser igual a cero                                  
					importeTotalIva: Math.round(detalleIva.impIva, 2), //suma de todos los ivas, en factura c debe ser cero       
					importeTotalTributo: 0,  //suma del arreglo de tributos
					iva: detalleIva.ivas,
				})
			}
		}

		try {
			await saveVentas(dataVentaRequest)
				.then(async res => {
					const ventaRes = res.data.venta;
					const { cliente } = ventaRes;
					const cobrosParcializados = venta.medioPagos
					const fecha = new Date(ventaRes.fecha);
					Swal.fire({
						toast: true,
						position: 'top',
						type: 'success',
						title: 'Venta realizada.',
						timer: 1500
					})
					handleCloseFE();
					handleClose();
					reset();
					const data = JSON.parse(sessionStorage.getItem('data'));
					const ticket = await createTicket({
						nombreLocal: data.sucursal.propietario.nombreNegocio || 'MAGENTA',
						propietario: {
							razonSocial: data.sucursal.propietario.razonSocial || '',
							cuit: data.sucursal.propietario.cuitCuil || '',
							domicilio: data.sucursal.propietario.domicilioFiscal || '',
							condicionFiscal: data.sucursal.propietario.condicionFiscal.nombre || '',
						},
						cliente: {
							nombre: `${cliente.idCliente.persona.nombre || ''} ${cliente.idCliente.persona.apellido || ''}`,
							domicilio: cliente.idCliente.persona.direccion || '',
							cuit: cliente.idCliente.persona.cuilCuit || cliente.idCliente.persona.dni,
							condicionFiscal: cliente.condicionFiscal.nombre || ''
						},
						fecha: fecha.toLocaleDateString(),
						hora: fecha.toLocaleTimeString(),
						venta: {
							tipoComprobante: ventaRes.tipoComprobante.nombre || '',
							puntoVenta,
							numComprobante: ventaRes.facturada ? ventaRes.numeroComprobanteAfip : ventaRes.numeroComprobante,
							items: ventaRes.items.map(i => {
								if (i.departamento) {
									const tasaIva = i.departamento.tasaIva;
									const precio = determinarPrecio(i.precio * i.cantidad, tasaIva, tipoComprobante)
									return {
										nombre: i.departamento.nombre,
										cantidad: `${i.cantidad} x ${i.precio}`,
										monto: precio
									}
								} else {
									const tasaIva = i.articulo.familia.departamento.tasaIva;
									const precio = i.articulo.pesable ? determinarPrecio(i.precio, tasaIva, tipoComprobante) : 
										determinarPrecio(i.precio * i.cantidad, tasaIva, tipoComprobante);
									return {
										nombre: i.articulo.nombre,
										cantidad: i.articulo.pesable ? `${i.cantidad} x ${i.articulo.precioFinal}` : `${i.cantidad} x ${i.precio}`,
										monto: precio	
									}
								}
							}),
							medioPagos: cobrosParcializados.map(cp => {
								return {
									nombre: cp.medioPago.nombre,
									monto: cp.monto
								}
							}),
							cae: ventaRes.cae,
							caeVencimiento: `${ventaRes.caeFechaVencimiento.substring(0, 4)}/${ventaRes.caeFechaVencimiento.substring(4, 6)}/${ventaRes.caeFechaVencimiento.substring(6, 8)}`,
							facturada: ventaRes.facturada,
							...(tipoComprobante === 11 ? {
								subtotal: ventaRes.total,
								descuentoRecargo: 0,
								iva0: 0,
								iva105: 0,
								iva21: 0,
							} : {
								subtotal: Math.round(ventaRes.total - detalleIva.iva0 - detalleIva.iva10 - detalleIva.iva21, 2),
								descuentoRecargo: 0,
								iva0: detalleIva.iva0 ? Math.round(detalleIva.iva0, 2) : 0,
								iva105: detalleIva.iva10 ? Math.round(detalleIva.iva10, 2) : 0,
								iva21: detalleIva.iva21 ? Math.round(detalleIva.iva21, 2) : 0,
							}),
							total: ventaRes.total,
							vuelto: 0,
							qr: generateQRUrl(ventaRes.caeVencimiento, data.sucursal.propietario.cuitCuil, puntoVenta,
								tipoComprobante, ventaRes.numeroComprobanteAfip, ventaRes.total, "PES", tipoDocumento, nroDocumento, ventaRes.cae)
						},
						turno: 0
					});
					const contenido = encodeURI(ticket.data);
					let excelWindow = window.open("");
					excelWindow.document.write(
						`<iframe width='100%' height='100%' margin='0px' padding='0px' src='data:application/pdf;base64, ${contenido}'>
						</iframe>`
					);
				})
				.catch (async error => {

					const errorData = {
							"mensaje": "Error al finalizar la venta en factura electronica",
							"detalle": error.message,
							"entidad": "VENTA",
							"tipo": "WEBAPP",
							"severidad": "HIGH",
							"reportado": "APPLICATION"
					};

					await saveError(errorData);
					Swal.fire({
						type: 'error',
						title: 'No se pudo proceder con la venta.',
						timer: 2500,
					});

				})
		} catch (error) {
			console.error(error.message)
		}
	}

	return (
		<Fragment>
			<Button onClick={handleShowFirst} variant="outline-danger" size="lg" className="widthCobrar"
				disabled={sessionStorage.getItem('api-key') ? false : true}>
				Facturacion Electronica <FontAwesomeIcon icon={faFileInvoiceDollar} />
			</Button>
			<Modal

				backdrop="static"
				show={showFE}
				onHide={handleCloseFE}
				size="xl"
				contentClassName="centrado"
			>
				<Modal.Header closeButton>
					<Modal.Title>Factura Electronica</Modal.Title>
				</Modal.Header>

				<Modal.Body>

					{/* MEDIOS DE PAGO, MONTO, DESCUENTO / RECARGO */}
					<div className="form-row">
						<div className="form-group col-md">
							<label htmlFor="inputPtoVenta">Punto de Venta</label>
							<div className="input-group-prepend">
								<span className="input-group-text" id="inputGroupPrepend">
									<FontAwesomeIcon icon={faDollarSign} />
								</span>
								<select
									id="inputPtoVenta"
									className="custom-select"
									name="ptoVenta"
									value={tipoDocumento}
									onChange={(e) => setPuntoVenta(e.target.value)}
								>
									{puntoVentas.map((pv, index) => (
										<option key={index} value={pv.Id}>
											{pv.Desc}
										</option>
									))}
								</select>
							</div>
						</div>

						<div className="form-group col-md">
							<label htmlFor="inputTpoCte">Tipo de Comprobante</label>
							<div className="input-group-prepend">
								<span className="input-group-text" id="inputGroupPrepend">
									<FontAwesomeIcon icon={faFileInvoice} />
								</span>
								<select
									id="inputTpoCte"
									className="custom-select"
									name="tipoComprobante"
									value={tipoComprobante}
									onChange={(e) => setTipoComprobante(e.target.value)}
								>
									{tipoComprobantes.map((tc, index) => (
										<option key={index} value={tc.Id}>
											{tc.Desc}
										</option>
									))}
								</select>
							</div>
						</div>

					</div>
					<div className="form-row">

						<div className="form-group col-md">
							<label htmlFor="inputTpoDoc">Tipo de Documento</label>
							<div className="input-group-prepend">
								<span className="input-group-text" id="inputGroupPrepend">
									<FontAwesomeIcon icon={faIdCard} />
								</span>
								<select
									id="inputTpoDoc"
									className="custom-select"
									name="tipoDocumento"
									value={tipoDocumento}
									onChange={(e) => setTipoDocumento(e.target.value)}
								>
									{tipoDocumentos.map((td, index) => (
										<option key={index} value={td.Id}>
											{td.Desc}
										</option>
									))}
								</select>
							</div>
						</div>

						<div className="form-group col-md">
							<label htmlFor="inputNroDoc">Nro Documento</label>
							<div className="input-group-prepend">
								<span className="input-group-text" id="inputGroupPrepend">
									<FontAwesomeIcon icon={faIdCardAlt} />
								</span>
								<input
									type="number"
									step="any"
									className="form-control"
									id="inputNroDoc"
									name="nroDocumento"
									placeholder="999999"
									value={nroDocumento}
									onChange={(e) => setNroDocumento(e.target.value)}
								/>
							</div>
						</div>
					</div>
					<div className="form-row">

						<div className="form-group col-md">
							<label htmlFor="inputConcepto">Concepto</label>
							<div className="input-group-prepend">
								<span className="input-group-text" id="inputGroupPrepend">
									<FontAwesomeIcon icon={faChevronCircleUp} />
								</span>
								<select
									id="inputConcepto"
									className="custom-select"
									name="comprobante"
									value={tipoConcepto}
									onChange={(e) => setTipoConcepto(e.target.value)}
								>
									{tipoConceptos.map((tc, index) => (
										<option key={index} value={tc.Id}>
											{tc.Desc}
										</option>
									))}
								</select>
							</div>
						</div>

						<div className="form-group col-md">
							<label htmlFor="inputCantRegistros">Cantidad de Registros</label>
							<div className="input-group-prepend">
								<span className="input-group-text" id="inputGroupPrepend">
									<FontAwesomeIcon icon={faSortAmountDown} />
								</span>
								<input
									type="number"
									step="any"
									className="form-control"
									id="inputCantRegistros"
									name="cantidadRegistros"
									placeholder="1"
									value={1}
									disabled={true}
								/>
							</div>
						</div>
					</div>

					<div className="form-row invisible">

						<div className="form-group col-md">
							<label htmlFor="inputConcepto">Concepto</label>
							<div className="input-group-prepend">
								<span className="input-group-text" id="inputGroupPrepend">
									<FontAwesomeIcon icon={faChevronCircleUp} />
								</span>
								<select
									id="inputConcepto"
									className="custom-select"
									name="comprobante"
									value={tipoConcepto}
									onChange={(e) => setTipoConcepto(e.target.value)}
								>
									{tipoConceptos.map((tc, index) => (
										<option key={index} value={tc.Id}>
											{tc.Desc}
										</option>
									))}
								</select>
							</div>
						</div>

						<div className="form-group col-md">
							<label htmlFor="inputCantRegistros">Cantidad de Registros</label>
							<div className="input-group-prepend">
								<span className="input-group-text" id="inputGroupPrepend">
									<FontAwesomeIcon icon={faSortAmountDown} />
								</span>
								<input
									type="number"
									step="any"
									className="form-control"
									id="inputCantRegistros"
									name="cantidadRegistros"
									placeholder="1"
									value={1}
									disabled={true}
								/>
							</div>
						</div>
					</div>

				</Modal.Body>

				<Modal.Footer>
					<button className="btn btn-outline-primary btn-lg col mr-1 ml-1"
						onClick={handleCloseFE}>
						Volver <FontAwesomeIcon icon={faArrowLeft} />
					</button>
					<button className="btn btn-outline-success btn-lg col mr-1 ml-1"
						onClick={finalizarVentaFE}>
						Finalizar Venta <FontAwesomeIcon icon={faCheck} />
					</button>
				</Modal.Footer>
			</Modal>
		</Fragment>
	);
};

export default withRouter(FacturaElectronicaModal);
