import React from "react";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

// services
import { recoverCompraDeleted } from "../../Services/compraService";

// Sweet alert
import Swal from "sweetalert2";

// router
import { withRouter } from "react-router-dom";

const ItemCompraEliminado = ({ compra, setIsLoad }) => {
  
  const estado = (estado) => {
    if (estado === 'ADEUDADA') return <p className="text-primary">ADEUDADA</p>;
    if (estado === 'PAGADA_PARCIALMENTE') return <p className="text-warning">PAGADA PARCIALMENTE</p>;
    if (estado === 'PAGADA') return <p className="text-success">PAGADA</p>;
}

  const recoverCompraSubmit = (compra) => {
    Swal.fire({
      title: "Estas seguro de recuperarla?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Recuperar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        // consulta a la api para eliminar
        recoverCompraDeleted(compra.id)
          .then((res) => {
            Swal.fire("Recuperado!", "La compra fue recuperada.", "success");
            setIsLoad(true);
          })
          .catch((error) => {
            Swal.fire({
              type: "error",
              title: "Error",
              text: "Hubo un error vuelve a intentar!",
            });
            console.log(error.response);
          });
      }
    });
  };

  return (
    <tr>
      <th scope="row">{compra.id}</th>
      <td>{compra.proveedor.nombre}</td>
      <td>${(compra.total).toFixed(2)}</td>
      <td>{estado(compra.estado)}</td>
      <td>{(compra.fecha).split('T')[0]}</td>
      <td>{compra.sucursal.nombre}</td>
      <td>{compra.tipoComprobante.nombre}</td>
      <td>{compra.numeroComprobante}</td>
      <td>
        <button
          className="btn text-warning"
          onClick={() => {
            recoverCompraSubmit(compra);
          }}
        >
          <FontAwesomeIcon icon={faTrash} /> Recuperar
        </button>
      </td>
    </tr>
  );
};

export default withRouter(ItemCompraEliminado);
