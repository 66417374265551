import React, { } from 'react';

// router
import { withRouter } from 'react-router-dom';

// pdf
import { Document, Page, PDFViewer } from '@react-pdf/renderer';

import styled from '@react-pdf/styled-components';


const Pdf = ({ history, location }) => {

    const pagos = location.state.pagos;

    const Col = styled.Text`
        justify-content: center
        fontSize: 10pt
        padding: 2em
    `;

    const Row = styled.View`
        flexDirection : row;
    `;

    return (
        <PDFViewer width="100%" height="1000" >
            <Document>
                <Page size="A4" >

                    {pagos.map((item) => {
                        return (
                            <Row>
                                <Col>{item.monto.toFixed(2) }</Col>
                                <Col>{ item.medioPago.nombre}</Col>
                                <Col>{ item.fecha.split('T')[0] }</Col>
                            </Row>
                        );
                    })}

                </Page>
            </Document>
        </PDFViewer>
    );
}


export default withRouter(Pdf);