import React from 'react';

// router
import { withRouter } from 'react-router-dom';

const ItemDetalleCaja = ({ item }) => {


    return (
        <tr>
            <th scope="row">{item.id}</th>
            <td>{item.motivoDetalleCaja}</td>
            <td>{item.descripcion}</td>
            <td>{item.cantidadBilletes}</td>
            <td>{item.cantidadCupones}</td>
            <td>${parseFloat(item.monto).toFixed(2)}</td>
            <td>{item.observacion}</td>
        </tr>
    );
}

export default withRouter(ItemDetalleCaja);