import axios from 'axios';
import {url} from './global';

export const getAllUnidadMedidas = async () => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/v2/unidad-medidas`,{headers});
    return res;
}

export const getByIdUnidadMedida = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/unidades-medidas/${id}`,{headers});
    return res;
}
