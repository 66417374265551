import React, { Fragment, useEffect, useState } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import { getAllArticulos } from '../../../Services/articuloService';
import ItemArticuloModal from './ItemArticuloModal';
import { withRouter } from 'react-router-dom';

const ModalArticulos = ({ setCodigoArticulo }) => {

    // let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));

    const [articulos, setArticulos] = useState([]);

    // pagination
    const [page, setPage] = useState(0);
    const [totalRegister, setTotalRegister] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [query, setQuery] = useState("");

    useEffect(() => {

        getAllArticulos(true, 0, query)
            .then(res => {
                setArticulos(res.data.content);
                setTotalRegister(res.data.totalElements);
                setTotalPage(res.data.totalPages);
            })
            .catch(error => {
                console.log(error.response)
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleBuscador = e => {

        setQuery(e.target.value);

        if (e.key === 'Enter') {
            e.preventDefault();

            getAllArticulos(true, 0, e.target.value)
                .then(res => {
                    setPage(0);
                    setArticulos(res.data.content);
                    setTotalRegister(res.data.totalElements);
                    setTotalPage(res.data.totalPages);
                })
                .catch(error => {
                    console.log(error.response)
                });
        }

    }

    const nextPage = () => {
        if (totalPage === 0) {
            Swal.fire({
                toast: true,
                position: "top",
                title: "No hay mas páginas",
                type: "info",
                showConfirmButton: false,
                timer: 1000,
            });
            return;
        }
        if (page === totalPage - 1) {
            Swal.fire({
                toast: true,
                position: "top",
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000
            })
            return;
        }

        getAllArticulos(true, page + 1, query)
            .then(res => {
                setPage(page + 1);
                setTotalPage(res.data.totalPages);
                setTotalRegister(res.data.totalElements);
                setArticulos(res.data.content);
            })
            .catch(error => {
                console.log(error);
            });

    }

    const previousPage = () => {
        if (page === 0) {
            Swal.fire({
                toast: true,
                position: "top",
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000
            })
            return;
        }

        getAllArticulos(true, page - 1, query)
            .then(res => {
                setPage(page - 1);
                setTotalPage(res.data.totalPages);
                setTotalRegister(res.data.totalElements);
                setArticulos(res.data.content);
            })
            .catch(error => {
                console.log(error);
            });


    }

    return (
        <Fragment>

            <button type="button" className="input-group-text"
                data-toggle="modal" data-target="#exampleModalScrollable">
                <FontAwesomeIcon icon={faSearch} />
            </button>

            <div className="modal fade w-100" id="exampleModalScrollable" tabIndex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalScrollableTitle">Articulos</h5>
                        </div>
                        <div className="modal-body">

                            <div className="d-flex bd-highlight">

                                <div className="p-2 flex-grow-1 bd-highlight">
                                    <div className="input-group-prepend input-group-lg">

                                        <input type="text"
                                            className="form-control form-lg"
                                            placeholder="Buscar"
                                            onKeyPress={(e) => handleBuscador(e)}
                                        />
                                        <span className="input-group-text" id="inputGroupPrepend">
                                            <FontAwesomeIcon icon={faSearch} />
                                        </span>
                                    </div>
                                </div>

                            </div>
                            {/* Inicio de paginador */}
                            <div className="d-flex flex-row bd-highlight">
                                <div className="p-2 bd-highlight">
                                    <button className="btn btn-outline-info"
                                        onClick={() => previousPage()}
                                        aria-label="Previous"
                                        type="button"
                                    >
                                        <span aria-hidden="true">&laquo; Anterior</span>
                                    </button>
                                </div>
                                <div className="p-2 bd-highlight mt-1">
                                    Registros: {totalRegister} | Pagina actual: {page + 1} | Total páginas : {totalPage}
                                </div>
                                <div className="p-2 bd-highlight">
                                    <button className="btn btn-outline-info"
                                        onClick={() => nextPage()}
                                        aria-label="Next"
                                        type="button"
                                    >
                                        <span aria-hidden="true">Siguiente &raquo;</span>
                                    </button>
                                </div>
                            </div>
                            <table className="table mt-3 text-center">
                                <thead className="thead-dark">
                                    <tr>
                                        <th scope="col">Id</th>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Costo</th>
                                        <th scope="col">Marca</th>
                                        <th scope="col">Seleccionar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {articulos.map((articulo) => (
                                        <ItemArticuloModal
                                            key={parseInt(articulo.id)}
                                            articulo={articulo}
                                            setCodigoArticulo={setCodigoArticulo} />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    );

}

export default withRouter(ModalArticulos);