import React from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faMoneyBill, faTrash } from '@fortawesome/free-solid-svg-icons'

// routes
import { withRouter } from 'react-router-dom';
import { deleteCuentasCorrienteProveedor } from '../../Services/cuentaCorrienteProveedorService';

// alert swal
import Swal from 'sweetalert2'

const ItemCuentaCorriente = ({ cuentaCorriente, history, setIsLoad }) => {

    const isDateNull = (fecha) => {
        if(fecha === null) return '-';
        else {
            let fechaFinal = fecha.split('T')[0];
            return fechaFinal;
        } 
    }

    const deleteCuentaCorriente = (cuentaCorriente) => {
        Swal.fire({
          title: 'Estas seguro de eliminar?',
          text: "Se eliminara la cuenta corriente del proveedor!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, Eliminar!',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.value) {
            deleteCuentasCorrienteProveedor(cuentaCorriente.id)
              .then(res => {
                Swal.fire(
                  'Eliminado!',
                  'La cuenta corriente fue eliminada.',
                  'success'
                );
                setIsLoad(true);
              })
              .catch(error => {
                Swal.fire({
                  type: 'error',
                  title: 'Error',
                  text: 'Hubo un error vuelve a intentar!'
                });
                console.log(error.response);
              })
          }
        })
      }

    return (
        <tr key={parseInt(cuentaCorriente.id)}>
            <th scope="row">{cuentaCorriente.id}</th>
            <td>{cuentaCorriente.proveedor.nombre}</td>
            <td>${cuentaCorriente.debo}</td>
            <td>{isDateNull(cuentaCorriente.fechaUltimoPago)}</td>
            <td>{isDateNull(cuentaCorriente.fechaProximoPago)}</td>
            <td>
                <button className="btn text-success"
                    onClick={() => { history.push(`/ingreso-pago-proveedor`,{cuentaCorriente: cuentaCorriente}) }}>
                    <FontAwesomeIcon icon={faMoneyBill} /> Pagos
                </button>
                <button className="btn text-danger"
                    onClick={() => { history.push(`/edit-cuenta-corriente-proveedores/${cuentaCorriente.id}`,{cuentaCorriente:cuentaCorriente}) }}>
                    <FontAwesomeIcon icon={faEdit} /> Editar
                </button>
                <button className="btn text-primary" title="Eliminar"
                    onClick={() => {deleteCuentaCorriente(cuentaCorriente)}}>
                    <FontAwesomeIcon icon={faTrash} /> Eliminar
                </button> 
            </td>
        </tr>
    );
}

export default withRouter(ItemCuentaCorriente);