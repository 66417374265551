import { GET_ROLES } from '../Actions/Types';

// cada reducer tiene su propio state
const initialState = {
    roles: []
}


export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ROLES:
            return {
                ...state,
                roles: action.payload
            }
        default:
            return state;
    }
}
