import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";

// components
import BotonBack from "../Utiles/BotonBack";
import Subtitulo from "../Utiles/Subtitulo";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright, faEdit } from "@fortawesome/free-solid-svg-icons";

// formik
import { useFormik } from "formik";
import * as Yup from "yup";
import Header from "../Header/Header";

// services
import { editByIdMarcas } from "../../Services/marcaService";
import { saveError } from "../../Services/errorService";

import Swal from "sweetalert2";

function EditMarca({ history, location }) {
  const marca = location.state.marca;

  const formik = useFormik({
    initialValues: {
      nombre: marca.nombre,
      ganancia: marca.ganancia || 0
    },
    validationSchema: Yup.object({
      nombre: Yup.string(),
      ganancia: Yup.number().min(0,"No puede ser menor a 0"),
    }),
    onSubmit: (values) => editMarca(values),
  });

  const editMarca = (values) => {
    // creo la marca actualizada
    const marcaUpdated = {
      ...values,
      id: marca.id,
    };

    editByIdMarcas(marcaUpdated)
      .then((res) => {
        history.goBack();
      })
      .catch(async (error) => {
        const errorData = {
          mensaje: "Error al intentar editar la marca",
          detalle: error.message,
          entidad: "MARCA",
          tipo: "WEBAPP",
          severidad: "HIGH",
          reportado: "APPLICATION",
        };

        await saveError(errorData);
        Swal.fire({
          text: "Error al editar la marca, verifique los datos.",
          type: "error",
          showConfirmButton: false,
          timer: 1500,
        });
        console.log(error.response.data);
      });
  };
  return (
    <Fragment>
      <Header />
      <div className="container-fluid mt-3">
        <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
          <BotonBack />
          <Subtitulo icon={faCopyright} title={"Marcas"} />
        </div>

        <div className="bg-gris p-4">
          <form onSubmit={formik.handleSubmit}>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="inputNombre">Nombre</label>
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroupPrepend">
                    <FontAwesomeIcon icon={faCopyright} />
                  </span>
                  <input
                    type="text"
                    id="inputNombre"
                    name="nombre"
                    className="form-control"
                    placeholder="Ej. Coca Cola, Paty..."
                    value={formik.values.nombre}
                    onChange={formik.handleChange}
                    onBlur={formik.handleChange}
                  />
                </div>
                {formik.touched.nombre && formik.errors.nombre ? (
                  <small className="form-text text-danger">
                    {formik.errors.nombre}
                  </small>
                ) : null}
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputGanancia">Ganancia</label>
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroupPrepend">
                    <FontAwesomeIcon icon={faCopyright} />
                  </span>
                  <input
                    type="number"
                    id="inputGanancia"
                    name="ganancia"
                    className="form-control"
                    placeholder="15"
                    value={formik.values.ganancia}
                    onChange={formik.handleChange}
                    onBlur={formik.handleChange}
                  />
                </div>
                {formik.touched.ganancia && formik.errors.ganancia ? (
                  <small className="form-text text-danger">
                    {formik.errors.ganancia}
                  </small>
                ) : null}
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-outline-danger">
                <FontAwesomeIcon icon={faEdit} /> Editar Marca
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default withRouter(EditMarca);
