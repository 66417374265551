import { GET_CUENTAS_CORRIENTES, GET_CUENTA_CORRIENTE, ADD_CUENTA_CORRIENTE, EDIT_CUENTA_CORRIENTE, DELETE_CUENTA_CORRIENTE } from '../Actions/Types';

// cada reducer tiene su propio state
const initialState = {
    cuentasCorrientes: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CUENTAS_CORRIENTES:
            return {
                ...state,
                cuentasCorrientes: action.payload
            }
        case GET_CUENTA_CORRIENTE:
            return {
                ...state,
                cuentaCorriente: action.payload
            }
        case ADD_CUENTA_CORRIENTE:
            return {
                ...state,
                cuentasCorrientes: [...state.cuentasCorrientes, action.payload]
            }
        case EDIT_CUENTA_CORRIENTE:
            return {
                ...state,
                cuentasCorrientes: (state.cuentasCorrientes.map(
                    cuentaCorriente => cuentaCorriente.idCuentaCorriente === action.payload.idCuentaCorriente
                        ? (cuentaCorriente = action.payload)
                        : cuentaCorriente
                ))
            }
        case DELETE_CUENTA_CORRIENTE:
            return {
                ...state,
                cuentasCorrientes: state.cuentasCorrientes.filter(cuentaCorriente => cuentaCorriente.idCuentaCorriente !== action.payload)
            }
        default:
            return state;
    }
}
