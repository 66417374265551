import React, { Fragment, useEffect, useState } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faFileInvoiceDollar, faCashRegister, faSearch, faList, faBuilding } from '@fortawesome/free-solid-svg-icons'

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo';
import ItemVenta from './ItemVenta';


// route
import { withRouter } from 'react-router-dom';

// services
import { getAllVentasBySucursalPage } from '../../Services/ventaService';
import { getAllSucursales } from '../../Services/sucursalService';

// alert swal
import Swal from 'sweetalert2'


const Venta = ({ history }) => {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    let rol = token.authorities[0];

    const [isLoad, setIsLoad] = useState(true);
    const [ventas, setVentas] = useState([]);
    const [sucursales, setSucursales] = useState([]);
    const [sucursal, setSucursal] = useState(token.empleado.sucursal.idSucursal);

    // pagination
    const [page, setPage] = useState(0);
    const [totalRegister, setTotalRegister] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [query, setQuery] = useState("");

    useEffect(() => {
        if (rol !== 'ROLE_ADMIN') history.goBack();

        let data = {
            query, sucursal
        }
        getAllVentasBySucursalPage(page, data)
            .then(res => {
                setVentas(res.data.content);
                setTotalRegister(res.data.totalElements);
                setTotalPage(res.data.totalPages);
                setIsLoad(false);
            })
            .catch(err => console.log('error'));

        getAllSucursales()
            .then(res => setSucursales(res.data))
            .catch(err => console.log(err));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad, sucursal]);

    const nextPage = () => {
        if (page === totalPage -1) {
            Swal.fire({
                toast: true,
                position: 'top',
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000
            })
            return;
        }

        let data = {
            query, sucursal
        }
        getAllVentasBySucursalPage(page + 1, data)
            .then(res => {
                setVentas(res.data.content);
                setTotalRegister(res.data.totalElements);
                setPage(page + 1)
                setTotalPage(res.data.totalPages);
                setIsLoad(false);
            })
            .catch(err => console.log('error'));


    }

    const previousPage = () => {
        if (page === 0) {
            Swal.fire({
                toast: true,
                position: 'top',
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000
            })
            return;
        }

        let data = {
            query, sucursal
        }
        getAllVentasBySucursalPage(page - 1, data)
            .then(res => {
                setVentas(res.data.content);
                setTotalRegister(res.data.totalElements);
                setPage(page - 1);
                setTotalPage(res.data.totalPages);
                setIsLoad(false);
            })
            .catch(err => console.log('error'));
    }

    const handleBuscador = (e) => {
        setQuery(e.target.value);
        if (e.key === 'Enter') {
            // verificar si la query isEmpty hacer otra consulta

            let data = {
                query: e.target.value, sucursal
            }
            getAllVentasBySucursalPage(0, data)
                .then(res => {
                    setPage(0);
                    setVentas(res.data.content);
                    setTotalRegister(res.data.totalElements);
                    setTotalPage(res.data.totalPages);
                    setIsLoad(false);
                })
                .catch(err => console.log('error'));

        }
    }

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faFileInvoiceDollar}
                        title={'Reporte de ventas'} />
                </div>

                <div className="d-flex bd-highlight">

                    <div className="p-2 flex-grow-1 bd-highlight">
                        <div className="input-group-prepend input-group-lg">
                            <span className="input-group-text" id="inputGroupPrepend">
                                <FontAwesomeIcon icon={faSearch} />
                            </span>
                            <input type="text"
                                className="form-control form-lg"
                                id="inputNombre"
                                name="precio"
                                placeholder="Buscar"
                                onKeyPress={(e) => { handleBuscador(e) }}
                            />
                        </div>
                    </div>

                    <div className="p-2 bd-highlight">
                        <button className="btn btn-outline-success btn-lg"
                            onClick={() => { history.push('/ventas') }}>
                            <FontAwesomeIcon icon={faCashRegister} /> Vender
                        </button>
                    </div>
                    <div className="p-2 bd-highlight">
                        <button className="btn btn-outline-warning btn-lg"
                            onClick={() => { history.push('/informes') }}>
                            <FontAwesomeIcon icon={faList} /> Informes
                            </button>
                    </div>
                    {(rol === 'ROLE_ADMIN') ?
                        <div className="p-2 bd-highlight">
                            <div className="dropdown">
                                <button className="btn btn-outline-info btn-lg dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <FontAwesomeIcon icon={faBuilding} /> Sucursales
                            </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    {sucursales.map(sucursal => {
                                        return <button key={sucursal.idSucursal} className="dropdown-item"
                                            onClick={() => setSucursal(sucursal.idSucursal)}>
                                            {sucursal.nombre}
                                        </button>;
                                    })}
                                </div>
                            </div>
                        </div>
                        : null
                    }
                </div>

                <div className="bg-gris p-3">

                    <div className="d-flex flex-row bd-highlight">
                        <div className="p-2 bd-highlight">
                            <button className="btn btn-outline-info"
                                onClick={() => previousPage()} aria-label="Previous">
                                <span aria-hidden="true">&laquo; Anterior</span>
                            </button>
                        </div>
                        <div className="p-2 bd-highlight mt-1">
                            Registros: {totalRegister} | Pagina actual: {page + 1} | Total páginas : {totalPage}
                        </div>
                        <div className="p-2 bd-highlight">
                            <button className="btn btn-outline-info"
                                onClick={() => nextPage()} aria-label="Next">
                                <span aria-hidden="true">Siguiente &raquo;</span>
                            </button>
                        </div>
                    </div>



                    <table className="table table-striped mt-3 text-center">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Total</th>
                                <th scope="col">Estado</th>
                                <th scope="col">Fecha - Hora</th>
                                <th scope="col">Sucursal</th>
                                <th scope="col">Comprobante</th>
                                <th scope="col">Cliente</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>

                            {ventas.map((venta) => (
                                <ItemVenta
                                    key={parseInt(venta.idVenta)}
                                    venta={venta}
                                    setIsLoad={setIsLoad} />
                            ))}

                        </tbody>
                    </table>

                </div>

            </div>
        </Fragment>
    );
}

export default withRouter(Venta);