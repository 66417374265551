import React, { Fragment, useState, useEffect } from 'react';

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo';
import Header from '../Header/Header';


// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBill, faBoxes, faDollarSign, faMoneyCheckAlt, faPlusSquare } from '@fortawesome/free-solid-svg-icons'

// router
import { withRouter } from 'react-router-dom';


// services
import { getAllVentasByCliente, getPagosByVenta, savePago } from '../../Services/clientePagoService';
import { getAllMediosPagos } from '../../Services/medioPagoService';
import { getVentaMediopagoByIdVenta } from '../../Services/ventaMedioPagoService';

// alert swal
import Swal from 'sweetalert2'

function IngresoPago({ history, location }) {

    //let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    let cuentaCorriente = location.state.cuentaCorriente;

    const [ventas, setVentas] = useState([]);
    const [venta, setVenta] = useState(null);

    const [mediosPagos, setMediosPagos] = useState([]);
    const [medioPago, setMedioPago] = useState(null);

    const [codigoCheque, setCodigoCheque] = useState();

    const [pagos, setPagos] = useState([]);
    const [monto, setMonto] = useState('');
    const [debe, setDebe] = useState(0);

    const [isLoad, setIsLoad] = useState(true);


    useEffect(() => {
        if (isLoad) {
            // GET ventas
            getAllVentasByCliente(cuentaCorriente.cliente.id.persona.id)
                .then(res => {                    
                    setVentas(res.data.content);
                })
                .catch(error => {
                    console.log(error);
                });
            // GET MEDIOS PAGOS
            getAllMediosPagos()
                .then(res => {
                    setMediosPagos(res.data);
                })
                .catch(error => console.log(error));
            setIsLoad(false);
        } else {
            setIsLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad]);

    const AddPago = () => {

        if (venta === null) {
            Swal.fire({
                toast: true,
                position: 'top',
                title: "Debe seleccionar una venta.",
                type: 'warning',
                showConfirmButton: false,
                timer: 1500
            });
            return;
        }

        if (medioPago === null) {
            Swal.fire({
                toast: true,
                position: 'top',
                title: "Debe seleccionar una forma de pago.",
                type: 'warning',
                showConfirmButton: false,
                timer: 1500
            });
            return;
        }

        let filterVenta = ventas.filter(function (vta) {
            return parseInt(vta.id, 10) === parseInt(venta.id, 10);
        });

        let medioFilter = mediosPagos.filter(function (mp) {
            return parseInt(mp.id, 10) === parseInt(medioPago, 10);
        });

        let pagoRequest = {
            cuentaCorriente: cuentaCorriente, monto: monto,
            venta: filterVenta[0], medioPago: medioFilter[0]
        }

        if (medioFilter[0].nombre === 'CHEQUE') {
            pagoRequest.cheque = {
                monto, numero: codigoCheque
            }
        }

        savePago(pagoRequest)
            .then(async res => {
                // GET ventas
                getPagosByVenta(venta.id)
                    .then(res => {
                        setPagos(res.data.content);
                        Swal.fire({
                            title: 'Pago ingresado correctamente',
                            type: 'success',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    })
                    .catch(error => {
                        console.log(error);
                    });


            })
            .catch(error => {
                setMonto(debe - calcularDebe())
                Swal.fire({
                    toast: true,
                    position: 'top',
                    title: "Se ingreso una cifra mayor a la adeudada.",
                    text: "\nSe igualara la misma. Reintente.",
                    type: 'warning',
                    showConfirmButton: false,
                    timer: 2000
                })
            });


    }

    const handleVentaSelected = (ven) => {

        if (ven === '') return;

        let filterVenta = ventas.filter(function (vta) {
            return parseInt(vta.id, 10) === parseInt(ven, 10);
        });

        setVenta(filterVenta[0]);
        getPagosByVenta(filterVenta[0].id)
            .then(res => {
                setPagos(res.data.content);
            })
            .catch(error => {
                console.log(error);
            });
        getVentaMediopagoByIdVenta(filterVenta[0].id)
            .then(res => {
                setDebe(res.data.monto - calcularDebe())
                setMonto(res.data.monto)
            })
            .catch(error => {
                console.log(error);
            })
        
    }

    const calcularDebe = () => {
        let suma = 0.0;
        pagos.map(pago => suma += Number(pago.monto));
        return suma.toFixed(2);
    }
    
    return (
        <Fragment>
            <Header/>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faMoneyBill}
                        title={'Pagos Clientes'} />
                </div>
                <div className="bg-gris p-4">
                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <label htmlFor="inputventas">Ventas</label>
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faBoxes} />
                                </span>
                                <select id="inputventas" className="custom-select" name="ventas"
                                    defaultValue={venta} onChange={(e) => handleVentaSelected(e.target.value)}>
                                    <option value={''}>-- Seleccione la venta a pagar --</option>
                                    {ventas.map((ven) => (
                                        <option key={parseInt(ven.id)} value={ven.id}>
                                            {`Venta Nº:${ven.id} - $${ven.total}`}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="form-group col-md-4">
                            <label htmlFor="inputMedioPago">Medio Pago</label>
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faDollarSign} />
                                </span>
                                <select id="inputMedioPago" className="custom-select" name="medioPago"
                                    defaultValue={medioPago} onChange={(e) => setMedioPago(e.target.value || null)}>
                                    <option value={null}>-- Seleccione medio de pago --</option>
                                    {mediosPagos.map((mp) => {
                                        if (mp.nombre === 'EFECTIVO' || mp.nombre === 'CHEQUE') {
                                            return (
                                                <option key={parseInt(mp.id)} value={mp.id}>
                                                    {mp.nombre}
                                                </option>
                                            );
                                        } else {
                                            return null;
                                        }

                                    })}
                                </select>
                            </div>

                        </div>

                        <div className="form-group col-md-4">
                            <label htmlFor="inputMonto">Monto</label>
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faMoneyBill} />
                                </span>
                                <input type="text"
                                    className="form-control"
                                    id="inputMonto"
                                    name="monto"
                                    defaultValue={monto}
                                    onChange={e => setMonto(e.target.value)} />
                            </div>

                        </div>
                    </div>

                    {(parseInt(medioPago) === 3) ?
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="inputCodigoCheque">Codigo Cheque</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faMoneyCheckAlt} />
                                    </span>
                                    <input type="text"
                                        className="form-control"
                                        id="inputCodigoCheque"
                                        name="codigoCheque"
                                        onChange={e => setCodigoCheque(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        : null
                    }

                    <div className="form-row justify-content-center">

                        <button className="btn btn-outline-success m-1"
                            onClick={() => AddPago()}>
                            Añadir Pago <FontAwesomeIcon icon={faPlusSquare} />
                        </button>

                    </div>

                    {venta ?
                        <Fragment>
                            <hr />
                            <h3 className="text-center">Datos Venta</h3>
                            <hr />
                            <div className="row">
                                <div className="col">
                                    <b>VENTA Nº:</b> {venta.id}
                                </div>
                                <div className="col">
                                    <b>FECHA CREACION:</b> {venta.createdAt.split('T')[0]}
                                </div>
                                <div className="col">
                                    <b>TOTAL:</b> ${Number(venta.total).toFixed(2)}
                                </div>
                                <div className="col">
                                    <b>DEBE:</b> ${Number(debe).toFixed(2) - calcularDebe()}
                                </div>
                            </div>
                        </Fragment>
                        : null}
                    <hr />
                    <div className="row">
                        <div className="col">
                            <h3 className="text-center">Pagos</h3>
                            <table className="table table-striped mt-3 text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Monto</th>
                                        <th scope="col">Medio Pago</th>
                                        <th scope="col">Fecha</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {pagos.map((pago) => (
                                        <tr key={parseInt(pago.id)}>
                                            <th scope="row">{pago.id}</th>
                                            <td>${(pago.monto).toFixed(2)}</td>
                                            <td>{pago.medioPago.nombre}</td>
                                            <td>{pago.createdAt.split('T')[0]}</td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
            </div>
        </Fragment>
    );
}

export default withRouter(IngresoPago);