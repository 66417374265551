import { GET_SUCURSALES, GET_SUCURSAL, ADD_SUCURSAL, EDIT_SUCURSAL, DELETE_SUCURSAL } from '../Actions/Types';

// cada reducer tiene su propio state
const initialState = {
    sucursales: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SUCURSALES:
            return {
                ...state,
                sucursales: action.payload
            }
        case GET_SUCURSAL:
            return {
                ...state,
                sucursal: action.payload
            }
        case ADD_SUCURSAL:
            return {
                ...state,
                sucursales: [...state.sucursales, action.payload]
            }
        case EDIT_SUCURSAL:
            return {
                ...state,
                sucursales: (state.sucursales.map(
                    sucursal => sucursal.idSucursal === action.payload.idSucursal
                        ? (sucursal = action.payload)
                        : sucursal
                ))
            }
        case DELETE_SUCURSAL:
            return {
                ...state,
                sucursales: state.sucursales.filter(sucursal => sucursal.idSucursal !== action.payload)
            }
        default:
            return state;
    }
}
