import React, { useState, useEffect } from 'react';
import './Login.css';

// services
import { login } from '../../Services/usuarioService';

// router
import { withRouter } from 'react-router-dom';

// Sweet alert
import Swal from 'sweetalert2';

function Login({ history }) {

    //<div className="custom-control custom-checkbox mb-3">
    //<input type="checkbox" className="custom-control-input" id="customCheck1" />
    //<label className="custom-control-label" for="customCheck1">Remember password</label>
    //</div>

    //<button className="btn btn-lg btn-google btn-block text-uppercase" type="submit"><i className="fab fa-google mr-2"></i> Sign in with Google</button>
    //<button className="btn btn-lg btn-facebook btn-block text-uppercase" type="submit"><i className="fab fa-facebook-f mr-2"></i> Sign in with Facebook</button>

    const [nameuser, setNameuser] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        if(sessionStorage.getItem('token')) {
            history.push("/home");
        }
    },[history]);

    const LoginSubmit = (e) => {
        e.preventDefault();

        login(nameuser, password)
            .then(res => {
                const body = res.data;
                sessionStorage.setItem('token', body.access_token)
                /* sessionStorage.setItem('data', JSON.stringify(body.empleado));
                if (body.empleado.sucursal.propietario.apiKey) {
                    sessionStorage.setItem('api-key', body.empleado.sucursal.propietario.apiKey)
                } */
                
                // let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));

                // if(token.authorities[0] !== 'ROLE_ADMIN') history.push('/apertura-caja');
                // else history.push('/home');

                history.push('/home');
                
            })
            .catch(res => {
                console.log(res.message);
                Swal.fire({
                    text: 'usuario o contraseña incorrecta, intente nuevamente',
                    type: 'info',
                    showConfirmButton: false,
                    timer: 2000
                });
            });

    }

    return (

        <div className="container">
            <div className="row">
                <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                    <div className="card card-signin my-5">
                        <div className="card-body">
                            <h5 className="card-title text-center">Inicio Sesion</h5>
                            <form className="form-signin" onSubmit={LoginSubmit}>
                                <div className="form-label-group">
                                    <input type="text"
                                        id="inputEmail"
                                        className="form-control"
                                        placeholder="Email address"
                                        onChange={e => setNameuser(e.target.value)}
                                        required autoFocus={true} />
                                    <label htmlFor="inputEmail">Nombre de usuario</label>
                                </div>

                                <div className="form-label-group">
                                    <input type="password"
                                        id="inputPassword"
                                        className="form-control"
                                        placeholder="Password"
                                        onChange={e => setPassword(e.target.value)}
                                        required />
                                    <label htmlFor="inputPassword">Password</label>
                                </div>
                                <button className="btn btn-lg btn-info btn-block text-uppercase" type="submit">Ingresar</button>
                                <hr className="my-4" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default withRouter(Login);