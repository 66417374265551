import React from "react";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

// routes
import { withRouter } from "react-router-dom";

// Sweet alert
import Swal from "sweetalert2";

// services
import { deleteById } from "../../Services/usuarioService";

const ItemUsuario = ({ usuario, history, setIsLoad }) => {
  const EliminarUsuarioSubmit = (id) => {
    Swal.fire({
      title: "Estas seguro de eliminar?",
      text: "Eliminando un usuario, no permitira iniciar sesion nuevamente!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        // consulta a la api para eliminar
        deleteById(id)
          .then((res) => {
            Swal.fire("Eliminado!", "El usuario fue eliminado.", "success");
            // INVOCAMOS EL ACTION
            setIsLoad(true);
          })
          .catch((error) => {
            Swal.fire({
              type: "error",
              title: "Error",
              text: "Hubo un error vuelve a intentar!",
            });
            console.log(error.response);
          });
      }
    });
  };

  return (
    <tr>
      <th scope="row">{usuario.id}</th>
      <td>{usuario.usuario.nombre}</td>
      <td>{usuario.usuario.apellido}</td>
      <td>{usuario.usuario.correo}</td>
      <td>{usuario.username}</td>
      <td>{usuario.roles[0].id.role.nombre}</td>
      <td>
        <button
          className="btn text-danger"
          onClick={() => {
            history.push(`/edit-usuario/${usuario.id}`, { usuario: usuario });
          }}
        >
          <FontAwesomeIcon icon={faEdit} /> Editar
        </button>
        <button
          className="btn text-primary"
          onClick={() => {
            EliminarUsuarioSubmit(usuario.id);
          }}
        >
          <FontAwesomeIcon icon={faTrash} /> Eliminar
        </button>
      </td>
    </tr>
  );
};

export default withRouter(ItemUsuario);
