import axios from 'axios';
import {url} from './global';

export const getArticulosMasVendidos = async (fechad, fechah, sucursal) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/ventas/articulos-topten/${fechad}/${fechah}/sucursal/${sucursal}`,{headers});
    return res;
}

export const getVentaTotal = async (fechad,fechah,sucursal) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/ventas/${fechad}/${fechah}/sucursal/${sucursal}`,{headers});
    return res;
}

// REPORTE VENTAS BY MEDIO DE PAGOS // MONTOS
export const getVentaTotalByMediosPagos = async (fechad,fechah,sucursal) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/ventas/medios-pagos/${fechad}/${fechah}/sucursal/${sucursal}`,{headers});
    return res;
}

// REPORTE VENTAS POR ARTICULOS AGRUPADOS
export const getReporteVentasByArticulo = async (fechaD, fechaH, sucursal) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/ventas/reportes/articulos/${fechaD}/${fechaH}/sucursal/${sucursal}`,{headers});
    return res;
}

// REPORTE VENTAS TOTALES
export const getReporteVentasTotal = async (fechaD, fechaH, sucursal) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/ventas/reportes/totales/${fechaD}/${fechaH}/sucursal/${sucursal}`,{headers});
    return res;
}

// REPORTE VENTAS BY MEDIO DE PAGOS // MONTOS BY CLIENTE
export const getVentaTotalByMediosPagosByCliente = async (fechad,fechah,sucursal, cliente) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/ventas/medios-pagos/${fechad}/${fechah}/sucursal/${sucursal}/cliente/${cliente}`,{headers});
    return res;
}

// REPORTE VENTAS POR ARTICULOS AGRUPADOS BY CLIENTE
export const getReporteVentasByArticuloByCliente = async (fechaD, fechaH, sucursal, cliente) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/ventas/reportes/articulos/${fechaD}/${fechaH}/sucursal/${sucursal}/cliente/${cliente}`,{headers});
    return res;
}

// REPORTE VENTAS TOTALES BY CLIENTE
export const getReporteVentasTotalByCliente = async (fechaD, fechaH, sucursal, cliente) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/ventas/reportes/totales/${fechaD}/${fechaH}/sucursal/${sucursal}/cliente/${cliente}`,{headers});
    return res;
}

// REPORTE VENTAS BY MEDIO DE PAGOS // MONTOS BY EMPLEADO
export const getVentaTotalByMediosPagosByEmpleado = async (fechad,fechah,sucursal, empleado) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/ventas/medios-pagos/${fechad}/${fechah}/sucursal/${sucursal}/empleado/${empleado}`,{headers});
    return res;
}

// REPORTE VENTAS POR ARTICULOS AGRUPADOS BY EMPLEADO
export const getReporteVentasByArticuloByEmpleado = async (fechaD, fechaH, sucursal, empleado) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/ventas/reportes/articulos/${fechaD}/${fechaH}/sucursal/${sucursal}/empleado/${empleado}`,{headers});
    return res;
}

// REPORTE VENTAS TOTALES BY EMPLEADO
export const getReporteVentasTotalByEmpleado = async (fechaD, fechaH, sucursal, empleado) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/ventas/reportes/totales/${fechaD}/${fechaH}/sucursal/${sucursal}/empleado/${empleado}`,{headers});
    return res;
}

// REPORTE VENTAS BY MEDIO DE PAGOS // MONTOS BY CLIENTE AND EMPLEADO
export const getVentaTotalByMediosPagosByClienteAndEmpleado = async (fechad,fechah,sucursal, cliente, empleado) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/ventas/medios-pagos/${fechad}/${fechah}/sucursal/${sucursal}/cliente/${cliente}/empleado/${empleado}`,{headers});
    return res;
}

// REPORTE VENTAS POR ARTICULOS AGRUPADOS BY CLIENTE AND EMPLEADO
export const getReporteVentasByArticuloByClienteAndEmpleado = async (fechaD, fechaH, sucursal, cliente, empleado) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/ventas/reportes/articulos/${fechaD}/${fechaH}/sucursal/${sucursal}/cliente/${cliente}/empleado/${empleado}`,{headers});
    return res;
}

// REPORTE VENTAS TOTALES BY CLIENTE AND EMPLEADO
export const getReporteVentasTotalByClienteAndEmpleado = async (fechaD, fechaH, sucursal, cliente, empleado) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/ventas/reportes/totales/${fechaD}/${fechaH}/sucursal/${sucursal}/cliente/${cliente}/empleado/${empleado}`,{headers});
    return res;
}