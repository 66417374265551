import axios from 'axios';
import {url} from './global';


export const getAllComprobantes = async () => {
    
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    
    let res = await axios.get(`${url}/comprobantes`,{headers});
    return res;
}

export const getByIdComprobante = async (id) => {
    
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    
    const res = await axios.get(`${url}/comprobantes/${id}`,{headers});
    return res;
}

export const editByIdComprobante = async (comprobante) => {
    
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    
    
    const res = await axios.put(`${url}/comprobantes/${comprobante.idTipoComprobante}`, comprobante,{headers});
    return res;
}

export const deleteByIdComprobante = async (id) => {
    
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    
    const res = await axios.delete(`${url}/comprobantes/${id}`,{headers});
    return res;
}

export const saveComprobante = async (comprobante) => {
    
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    
    const res = await axios.post(`${url}/comprobantes`,comprobante,{headers});
    return res;
}
