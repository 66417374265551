import React from "react";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

// services
import { recoverTarjetaDeleted } from "../../Services/tarjetaService";

// Sweet alert
import Swal from "sweetalert2";

// router
import { withRouter } from "react-router-dom";

const ItemTarjetaEliminada = ({ tarjeta, setIsLoad }) => {
  const recoverTarjetaSubmit = (tarjeta) => {
    Swal.fire({
      title: "Estas seguro de recuperarla?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Recuperar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        // consulta a la api para eliminar
        recoverTarjetaDeleted(tarjeta.id)
          .then((res) => {
            Swal.fire("Recuperado!", "La tarjeta fue recuperada.", "success");
            setIsLoad(true);
          })
          .catch((error) => {
            Swal.fire({
              type: "error",
              title: "Error",
              text: "Hubo un error vuelve a intentar!",
            });
            console.log(error.response);
          });
      }
    });
  };

  return (
    <tr>
      <th scope="row">{tarjeta.id}</th>
            <td>{tarjeta.nombre}</td>
            <td>{tarjeta.tipoTarjeta}</td>
      <td>
        <button
          className="btn text-warning"
          onClick={() => {
            recoverTarjetaSubmit(tarjeta);
          }}
        >
          <FontAwesomeIcon icon={faTrash} /> Recuperar
        </button>
      </td>
    </tr>
  );
};

export default withRouter(ItemTarjetaEliminada);
