import React from 'react';

// router
import { withRouter } from 'react-router-dom';

const ItemDetallePago = ({ item }) => {

    return (
        <tr>
            <th scope="row">{item.id}</th>
            <td>${item.monto.toFixed(2)}</td>
            <td>{item.medioPago.nombre}</td>
            <td>{item.createdAt.split('T')[0]}</td>
        </tr>
    );
}

export default withRouter(ItemDetallePago);