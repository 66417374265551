import { GET_ARTICULOS, GET_ARTICULO, ADD_ARTICULO, DELETE_ARTICULO, EDIT_ARTICULO } from '../Actions/Types';

// cada reducer tiene su propio state
const initialState = {
    articulos: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ARTICULOS:
            return {
                ...state,
                articulos: action.payload
            }
        case GET_ARTICULO:
            return {
                ...state,
                articulo: action.payload
            }
        case ADD_ARTICULO:
            return {
                ...state,
                articulos: [...state.articulos, action.payload]
            }
        case EDIT_ARTICULO:
            return {
                ...state,
                articulos: (state.articulos.map(
                    articulo => articulo.idArticulo === action.payload.idArticulo
                        ? (articulo = action.payload)
                        : articulo
                ))
            }
        case DELETE_ARTICULO:
            return {
                ...state,
                articulos: state.articulos.filter(articulo => articulo.idArticulo !== action.payload)
            }
        default:
            return state;
    }
}
