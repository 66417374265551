import React from 'react';

// Modal
import ModalDetalleCaja from './ModalDetalleCaja/ModalDetalleCaja';

// router
import { withRouter } from 'react-router-dom';

const ItemCajaReporte = ({ item }) => {

    return (
        <tr>
            <th scope="row">{item.idCaja}</th>
            <td>{item.puntoDeVenta.nombre}</td>
            <td>{item.fechaInicial.split('T')[0]} / {item.fechaInicial.split('T')[1].replace('-03:00', '')}</td>
            <td>{(item.fechaFinal) ?
                `${item.fechaFinal.split('T')[0]} / ${item.fechaFinal.split('T')[1].replace('-03:00', '')}`
                : '-'
            }</td>
            <td>${parseFloat(item.montoInicial).toFixed(2)}</td>
            <td>${(item.montoFinal != null) ?
                `${parseFloat(item.montoFinal).toFixed(2)}` : `${"0.0"}`
            }
            </td>
            <td>{(item.abierta) ?
                <p className="text-success">ABIERTA</p> :
                <p className="text-danger">CERRADA</p>}
            </td>
            <td>
                <ModalDetalleCaja caja={item} />
            </td>
        </tr>
    );
}

export default withRouter(ItemCajaReporte);