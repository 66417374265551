import { GET_PROVEEDORES, GET_PROVEEDOR, ADD_PROVEEDOR, EDIT_PROVEEDOR, DELETE_PROVEEDOR } from '../Actions/Types';

// cada reducer tiene su propio state
const initialState = {
    proveedores: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PROVEEDORES:
            return {
                ...state,
                proveedores: action.payload
            }
        case GET_PROVEEDOR:
            return {
                ...state,
                proveedor: action.payload
            }
        case ADD_PROVEEDOR:
            return {
                ...state,
                proveedores: [...state.proveedores, action.payload]
            }
        case EDIT_PROVEEDOR:
            return {
                ...state,
                proveedores: (state.proveedores.map(
                    proveedor => proveedor.idProveedor === action.payload.idProveedor
                        ? (proveedor = action.payload)
                        : proveedor
                ))
            }
        case DELETE_PROVEEDOR:
            return {
                ...state,
                proveedores: state.proveedores.filter(proveedor => proveedor.idProveedor !== action.payload)
            }
        default:
            return state;
    }
}
