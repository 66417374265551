import React, { Fragment, useEffect, useState, useRef } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faMoneyBillWave, faDollarSign, faCashRegister, faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons'

// components
//import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo';

// route
import { withRouter } from 'react-router-dom';

// services
import { getAllPuntoVentas } from '../../Services/puntoVentaService';
import { save, isCajaActiva } from '../../Services/cajaService';

// alert swal
import Swal from 'sweetalert2'

const OpenCaja = ({ history }) => {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));

    const [puntosVentas, setPuntosVentas] = useState([]);
    const [puntoVenta, setPuntoVenta] = useState();

    const sucursal = token.empleado.sucursal;
    const [isLoad, setIsLoad] = useState(true);
    const [isCaja, setIsCaja] = useState(true);

    const inputMontoRef = useRef(null);

    useEffect(() => {

        if (isLoad) {
            //TODO: revisar para conseguir puntos de venta por sucursal
            getAllPuntoVentas(true, 0)
                .then(res => {
                    setPuntosVentas(res.data.content);
                    setIsLoad(false);
                })
                .catch(error => console.log(error.response));
        } else {
            setIsLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad]);

    const verificarCaja = () => {

        // filtro el punto de venta seleccionada mediante su id
        let ptoFiltered = puntosVentas.filter(function (pv) {
            return pv.idPuntoDeVenta === parseInt(puntoVenta, 10);
        });

        isCajaActiva(puntoVenta)
            .then(async res => {
                let message = res.data.message;
                setIsCaja(message);

                if(message === true) {
                    Swal.fire({
                        title: 'Ya existe una caja abierta!',
                        type: 'success',
                        showConfirmButton: false,
                        timer: 2000
                    });
                    history.push('./home');
                    sessionStorage.setItem('ptoventa', JSON.stringify(ptoFiltered[0]));
                }

            })
            .catch(error => {
                console.log(error);
            })
    }

    const iniciarCaja = () => {

        // filtro el punto de venta seleccionada mediante su id
        let ptoFiltered = puntosVentas.filter(function (pv) {
            return pv.idPuntoDeVenta === parseInt(puntoVenta, 10);
        });

        let caja = {
            puntoDeVenta: ptoFiltered[0],
            montoInicial: inputMontoRef.current.value
        }

        save(caja)
            .then(res => {                
                Swal.fire({
                    title: 'Se abrio una caja correctamente!',
                    type: 'success',
                    showConfirmButton: false,
                    timer: 2000
                });
                history.push('/home');
                sessionStorage.setItem('ptoventa', JSON.stringify(ptoFiltered[0]));
            })
            .catch(error => {
                Swal.fire({
                    title: 'No se pudo crear la caja, ingrese monto inicial!',
                    type: 'warning',
                    showConfirmButton: false,
                    timer: 2000
                });
            });
    }

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-center">
                    <Subtitulo
                        icon={faMoneyBillWave}
                        title={'Apertura de caja'} />
                </div>

                <div className="bg-gris p-3">

                    {(!isCaja) ?
                        <div>
                            <div className="row justify-content-center mt-3">

                                <div className="col-6">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroupPrepend">
                                            <FontAwesomeIcon icon={faDollarSign} />
                                        </span>
                                        <input type="text"
                                            className="form-control"
                                            step="any"
                                            id="inputCosto"
                                            name="costo"
                                            ref={inputMontoRef}
                                            placeholder="Ingrese monto inicial" />
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center pt-2">
                                <div className="col-6 text-center">
                                    <button className="btn btn-outline-success btn-lg"
                                        onClick={() => iniciarCaja()}>
                                        Iniciar caja <FontAwesomeIcon icon={faCashRegister} />
                                    </button>
                                    <button className="btn btn-outline-info btn-lg ml-1"
                                        onClick={() => setIsCaja(true)}>
                                        Volver <FontAwesomeIcon icon={faArrowAltCircleLeft} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <div className="row justify-content-center">
                                <div className="col-6">
                                    <label htmlFor="inputComprobante">Seleccion un punto de venta</label>
                                    <select id="inputPuntoVenta" className="custom-select" name="puntoVenta"
                                        value={puntoVenta} onChange={(e) => setPuntoVenta(e.target.value)}>
                                        <option value={null}>-- Seleccione un punto de venta --</option>
                                        {puntosVentas.map((pv, index) => (
                                            <option key={index} value={pv.idPuntoDeVenta}>{pv.nombre}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="row justify-content-center pt-2">
                                <div className="col-6 text-center">
                                    <button className="btn btn-outline-success btn-lg"
                                        onClick={() => verificarCaja()}>
                                        Seleccionar <FontAwesomeIcon icon={faCheck} />
                                    </button>
                                    
                                </div>
                            </div>
                        </div>
                    }



                    {/* <div className="d-flex bd-highlight">
                        <div className="p-2 bd-highlight">
                        </div>
                        <div className="p-2 flex-grow-1 bd-highlight">
                            <div className="input-group-prepend input-group-lg">

                                <input type="text"
                                    className="form-control form-lg"
                                    id="inputNombre"
                                    name="precio"
                                    placeholder="Buscar"
                                    onChange={e => onChangeMarcas(e)}
                                />
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faSearch} />
                                </span>
                            </div>
                        </div>
                    </div> */}



                </div>

            </div>
        </Fragment>
    );
}

export default withRouter(OpenCaja);