import React, { Fragment, useState } from 'react';

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo'


// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faDonate, faPercentage, faMoneyBill, faBox, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

// router
import { withRouter } from 'react-router-dom';


// services
import { saveOferta } from '../../Services/ofertaService';

// modals
import ModalArticulosOferta from './ArticuloModalOfertas/ModalArticuloOferta';

// alert
import Swal from 'sweetalert2';

function AddOfertas({ history }) {

    const [nombre, setNombre] = useState('');
    const [articulo, setArticulo] = useState();
    const [fechaFinOferta, setFechaFinOferta] = useState();
    const [porcentajeDescuento, setPorcentajeDescuento] = useState(0);
    const [precioFinal, setPrecioFinal] = useState(0);
    const [promocionCantidad, setPromocionCantidad] = useState(0);

    const addOfertaSubmit = (e) => {
        // validaciones

        let articuloRequest = {
            codigo: articulo
        }
        // creamos el departamento
        let oferta = {
            articulo: articuloRequest, fechaFinOferta,
            nombre, porcentajeDescuento,
            precioFinal, promocionCantidad
        }

        saveOferta(oferta)
            .then(res => {
                history.goBack();
            })
            .catch(res => {
                Swal.fire({
                    title: 'No se pudo crear la oferta',
                    text: res.response.data.mensaje,
                    type: 'error',
                    showConfirmButton: false,
                    timer: 2000
                });
            });
    }


    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faDonate}
                        title={'Ofertas'} />
                </div>

                <div className="bg-gris p-4">
                    <div>


                        <div className="form-row">

                            <div className="form-group col-md-4">
                                <label htmlFor="inputNombre">Articulo</label>
                                <div className="input-group-prepend">
                                    <ModalArticulosOferta setArticulo={setArticulo} />
                                    <input type="text"
                                        id="inputNombre"
                                        name="nombre"
                                        defaultValue={articulo}
                                        className="form-control"
                                        placeholder="Ingrese el codigo de un articulo"
                                        onChange={e => setArticulo(e.target.value)} />
                                </div>
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="inputFechaFin">Fecha Fin</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                    </span>
                                    <input type="date"
                                        className="form-control"
                                        id="inputFechaFin"
                                        name="fechaFinOferta"
                                        onChange={e => setFechaFinOferta(e.target.value)} />
                                </div>
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="inputNombre">Nombre oferta</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faDonate} />
                                    </span>
                                    <input type="text"
                                        id="inputNombre"
                                        name="nombre"
                                        className="form-control"
                                        placeholder="Ej. Lacteos, Gaseosas..."
                                        onChange={e => setNombre(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="inputPrecioFinal">Precio Final</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faMoneyBill} />
                                    </span>
                                    <input type="number"
                                        step="any"
                                        className="form-control"
                                        id="inputPrecioFinal"
                                        name="precioFinal"
                                        placeholder="Ingrese precio final"
                                        onChange={e => setPrecioFinal(e.target.value)} />
                                </div>
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="inputPorcentajeDescuento">Porcentaje Descuento</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faPercentage} />
                                    </span>
                                    <input type="number"
                                        step="any"
                                        className="form-control"
                                        id="inputPorcentajeDescuento"
                                        name="porcentajeDescuento"
                                        placeholder="Ingrese porcetaje de descuento"
                                        onChange={e => setPorcentajeDescuento(e.target.value)} />
                                </div>
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="inputCantidadArticulo">Cantidad de Articulos</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faBox} />
                                    </span>
                                    <input type="number"
                                        step="any"
                                        className="form-control"
                                        id="inputCantidadArticulo"
                                        name="promocionCantidad"
                                        placeholder="Ingrese la cantidad de articulos"
                                        onChange={e => setPromocionCantidad(e.target.value)} />
                                </div>
                            </div>

                        </div>


                        <div className="text-center">
                            <button type="submit" className="btn btn-outline-success"
                                onClick={() => { addOfertaSubmit() }}>
                                <FontAwesomeIcon icon={faPlus} /> Crear Oferta</button>
                        </div>


                    </div>
                </div>



            </div>
        </Fragment>
    );
}

export default withRouter(AddOfertas);