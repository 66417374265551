import React, { Fragment, useState, useEffect, useRef } from 'react';

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo'

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBarcode, faPlus,
    faDollarSign, faCartPlus, faFileAlt, faKeyboard, faMinus, faTrashAlt,
    faPercent,
    faArchive,
    faBalanceScale,
    faSortNumericUp,
} from '@fortawesome/free-solid-svg-icons'

// components
import ModalArticulo from './ArticuloModal/ModalArticulo';
import Header from '../Header/Header';

// services
import { getAllFamilias } from '../../Services/familiaService';
import { getAllMarcas } from '../../Services/marcaService';
import { saveArticulosCompuestos, getByIdArticulos } from '../../Services/articuloService';
import { getAllUnidadMedidas } from '../../Services/unidadMedidaService';
import { saveError } from '../../Services/errorService';

// routes
import { withRouter } from 'react-router-dom';

// Sweet alert
import Swal from 'sweetalert2';

// formik
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';



const AddArticulosCompuestos = ({ history }) => {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    let role = token.authorities[0];

    const [itemsCompra, setItemsCompra] = useState([]);

    const [isLoad, setIsLoad] = useState(true);

    const [items, setItems] = useState([]);
    const [codigoArticulo, setCodigoArticulo] = useState();
    const [cantidadArticulo, setCantidadArticulo] = useState(1);

    const [codigosBarra, setCodigosBarra] = useState([]);

    const [familias, setFamilias] = useState([]);
    const [marcas, setMarcas] = useState([]);
    const [unidadMedidas, setUnidadMedidas] = useState([]);

    const inputTotalRef = useRef(null);

    useEffect(() => {

        if (role !== 'ROLE_ADMIN_MAGENTA') history.goBack();

        if (isLoad) {
            getAllFamilias(true, 0, "", 100)
                .then(res => setFamilias(res.data.content))
                .catch(err => console.log('error'));
            getAllMarcas(true, 0, "", 100)
                .then(res => {
                    setMarcas(res.data.content)
                })
                .catch(err => console.log('error'));
            getAllUnidadMedidas(true, 0, "", 100)
                .then(res => {
                    setUnidadMedidas(res.data.content)
                })
                .catch(err => console.log('error'));
            setIsLoad(false);
        } else {
            setIsLoad(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // atributos de articulo
    const formik = useFormik({
        initialValues: {
            nombre: '',
            costo: 0.0,
            precioFinal: 0.0,
            puntos: 0,
            codigo: '',
            descripcion: '',
            pesable: false,
            balanza: false,
            pesoPromedio: 0,
            compuesto: true,
            porcentajeGanancia: 0,
            familia: '',
            marca: '',
            unidadMedida: ''
        },
        validationSchema: Yup.object({
            nombre: Yup.string().required("Obligatorio"),
            costo: Yup.number().required("Obligatorio").min(0),
            codigo: Yup.string().matches(/^[0-9]+$/, "Solo números").required("Obligatorio"),
            familia: Yup.object().required("Obligatorio"),
            marca: Yup.object().required("Obligatorio"),
            unidadMedida: Yup.object().required("Obligatorio")
        }),
        onSubmit: values => addArticuloSubmit(values)
    });

    const handleCodigosBarra = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            let codigoBarra = { codigo: e.target.value };
            setCodigosBarra([
                ...codigosBarra,
                codigoBarra
            ]);
            e.target.value = '';
        }
    }

    const deleteCodigoBarra = (e, codigo) => {
        e.preventDefault();
        let codigosBarraFilter = codigosBarra.filter(function (cv) {
            return cv.codigo !== codigo;
        });
        setCodigosBarra(codigosBarraFilter);
    }

    const handleKeyPress = (event) => {


        // codigo y cantidad

        // validar que el articulo no existe en los detalles
        let isPresent = false;
        items.forEach(item => {
            if (parseInt(item.id, 10) === parseInt(codigoArticulo, 10) ||
                parseInt(item.codigo, 10) === parseInt(codigoArticulo, 10)) isPresent = true;
        });

        // si ya se encuentra presento salgo de la funcion
        if (isPresent) {
            setCodigoArticulo('')
            setCantidadArticulo(1);
            inputTotalRef.current.focus()
            Swal.fire({
                toast: true,
                position: 'top',
                title: 'El artículo ya se ingresó.',
                type: 'info',
                showConfirmButton: false,
                timer: 1500
            })
            return;
        }

        getByIdArticulos(codigoArticulo)
            .then(async data => {

                // datos para calcular precio articulo
                let articulo = data.data;

                // items para mostra detalle en pantalla
                let item = {
                    id: articulo.id,
                    codigo: articulo.codigo,
                    articulo: articulo.nombre,
                    costo: articulo.costo,
                    familia: articulo.familia,
                    cantidad: parseFloat(cantidadArticulo, 10),
                }

                // items para guardar en la base de datos
                let itemCompra = {
                    idArticulo: articulo.id,
                    cantidad: item.cantidad
                }

                // ingresamos items que se guardaran en la bd
                setItemsCompra([...itemsCompra, itemCompra]);
                // ingresamos items de  que se mostraran en pantalla
                setItems([...items, item]);

                setCodigoArticulo('');
                setCantidadArticulo(1);
                inputTotalRef.current.focus()
            })
            .catch(error => {
                setCodigoArticulo('');
                setCantidadArticulo(1);
                inputTotalRef.current.focus()
                Swal.fire({
                    toast: true,
                    position: 'top',
                    title: 'El artículo no existe.',
                    text: 'No se encontró en la base de datos.',
                    type: 'info',
                    timer: 2000,
                    showConfirmButton: false
                })
            });


    }

    // elimina items de la lista de articulos
    const deleteItem = (articulo) => {
        let itemsDelete = items.filter((item) => {
            return parseInt(item.id, 10) !== parseInt(articulo.id, 10);
        });

        let itemsCompraDelete = itemsCompra.filter((item) => {
            return parseInt(item.id, 10) !== parseInt(articulo.id, 10);
        });

        setItemsCompra(itemsCompraDelete);
        setItems(itemsDelete);
    }

    const addArticuloSubmit = () => {

        const { nombre, costo, precioFinal, puntos, codigo, descripcion, pesable, balanza,
            pesoPromedio, compuesto, porcentajeGanancia, familia, marca, unidadMedida } = formik.values;

        let articulo = {
            nombre, costo, precioFinal, puntos, codigo, descripcion, pesable, balanza,
            pesoPromedio, compuesto, porcentajeGanancia,
            familiaId: familia.id, marcaId: marca.id, unidadMedidaId: unidadMedida.id, codigosBarra,
            articulos: itemsCompra
        }

        saveArticulosCompuestos(articulo)
            .then(res => {
                history.goBack();
            })
            .catch(async error => {
                const errorData = {
                    "mensaje": "Error al intentar agregar un articulo",
                    "detalle": error.message,
                    "entidad": "ARTICULO",
                    "tipo": "WEBAPP",
                    "severidad": "HIGH",
                    "reportado": "APPLICATION"
                };

                await saveError(errorData);
                Swal.fire({
                    title: "Faltan datos o algunos son incorrectos.",
                    text: error.message,
                    type: 'error',
                    showConfirmButton: false,
                    timer: 2000
                });
            });

    }

    return (
        <Fragment>
            <Header />
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faBarcode}
                        title={'Articulos Compuestos'} />
                </div>

                <div className="bg-gris p-4">

                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <label htmlFor="inputNombre">Nombre</label>
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faCartPlus} />
                                </span>
                                <input
                                    type="text"
                                    id="inputNombre"
                                    name="nombre"
                                    className="form-control"
                                    placeholder="Ej. Lacteos, Gaseosas..."
                                    value={formik.values.nombre}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            {formik.touched.nombre && formik.errors.nombre ?
                                <small className="form-text text-danger">
                                    {formik.errors.nombre}
                                </small>
                                : null}
                        </div>

                        <div className="form-group col-md-4">
                            <label htmlFor="inputCosto">Costo</label>
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faDollarSign} />
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    step="any"
                                    id="inputCosto"
                                    name="costo"
                                    placeholder="Ej. 100, 200,..."
                                    value={formik.values.costo}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            {formik.touched.costo && formik.errors.costo ?
                                <small className="form-text text-danger">
                                    {formik.errors.costo}
                                </small>
                                : null}
                        </div>

                        <div className="form-group col-md-4">
                            <label htmlFor="inputPrecioFinal">Precio Final</label>
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faDollarSign} />
                                </span>
                                <input
                                    type="number"
                                    step="any"
                                    id="inputPrecioFinal"
                                    name="precioFinal"
                                    className="form-control"
                                    placeholder="$250"
                                    value={formik.values.precioFinal}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <label htmlFor="inputPGanancia">Porcentaje Ganancia</label>
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faPercent} />
                                </span>
                                <input
                                    type="text"
                                    id="inputPGanancia"
                                    name="porcentajeGanancia"
                                    className="form-control"
                                    placeholder="10, 20"
                                    value={formik.values.porcentajeGanancia}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                        </div>

                        <div className="form-group col-md-4">
                            <label htmlFor="inputPesable">Pesable</label>
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faArchive} />
                                </span>
                                <select
                                    id="inputPesable"
                                    className="custom-select"
                                    name="pesable"
                                    value={formik.values.pesable}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    disabled>
                                    <option value={true}>Si</option>
                                    <option value={false}>No</option>
                                </select>
                            </div>
                        </div>

                        <div className="form-group col-md-4">
                            <label htmlFor="inputPesoPromedio">Peso promedio</label>
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faBalanceScale} />
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputPesoPromedio"
                                    name="pesoPromedio"
                                    placeholder="medida en gramos"
                                    value={formik.values.pesoPromedio}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-row">

                        <div className="form-group col-md-4">
                            <label htmlFor="inputBalanza">Balanza</label>
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faBalanceScale} />
                                </span>
                                <select
                                    id="inputBalanza"
                                    className="custom-select"
                                    name="balanza"
                                    value={formik.values.balanza}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}>
                                    <option value={true}>Si</option>
                                    <option value={false}>No</option>
                                </select>
                            </div>
                        </div>

                        <div className="form-group col-md-4">
                            <label htmlFor="inputCodProducto">Codigo del producto</label>
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faKeyboard} />
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputCodProducto"
                                    name="codigo"
                                    placeholder="Ej 100"
                                    value={formik.values.codigo}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            {formik.touched.codigo && formik.errors.codigo ?
                                <small className="form-text text-danger">
                                    {formik.errors.codigo}
                                </small>
                                : null}
                        </div>

                        <div className="form-group col-md-4">
                            <label htmlFor="inputDescripcion">Descripcion</label>
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faFileAlt} />
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputDescripcion"
                                    name="descripcion"
                                    placeholder="Ej. producto envasado"
                                    value={formik.values.descripcion}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} />
                            </div>
                        </div>
                    </div>

                    <div className="form-row">

                        <div className="form-group col-md-4">
                            <label htmlFor="inputFamilia">Familia</label>
                            <div>
                                <Select
                                    placeholder={"Seleccionar una familia"}
                                    options={familias}
                                    onChange={value => formik.setFieldValue('familia', value)}
                                    getOptionValue={(values) => values.id}
                                    getOptionLabel={(values) => values.nombre}
                                >
                                </Select>
                            </div>
                            {formik.touched.familia && formik.errors.familia ?
                                <small className="form-text text-danger">
                                    {formik.errors.familia}
                                </small>
                                : null}
                        </div>

                        <div className="form-group col-md-4">
                            <label htmlFor="inputMarca">Marca</label>
                            <div>
                                <Select
                                    placeholder={"Seleccionar una marca"}
                                    options={marcas}
                                    onChange={value => formik.setFieldValue('marca', value)}
                                    getOptionValue={(values) => values.id}
                                    getOptionLabel={(values) => values.nombre}
                                >
                                </Select>
                            </div>
                            {formik.touched.marca && formik.errors.marca ?
                                <small className="form-text text-danger">
                                    {formik.errors.marca}
                                </small>
                                : null}
                        </div>

                        <div className="form-group col-md-4">
                            <label htmlFor="inputUnidadMedida">Unidad Medida</label>
                            <div>
                                <Select
                                    placeholder={"Seleccionar una unidad de medida"}
                                    options={unidadMedidas}
                                    onChange={value => formik.setFieldValue('unidadMedida', value)}
                                    getOptionValue={(values) => values.id}
                                    getOptionLabel={(values) => values.nombre}
                                >
                                </Select>
                            </div>
                            {formik.touched.unidadMedida && formik.errors.unidadMedida ?
                                <small className="form-text text-danger">
                                    {formik.errors.unidadMedida}
                                </small>
                                : null}
                        </div>

                    </div>

                    <div className="form-row">

                        <div className="form-group col-md-6">
                            <label htmlFor="inputPuntos">Puntos</label>
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faSortNumericUp} />
                                </span>
                                <input
                                    type="number"
                                    className="form-control"
                                    step="any"
                                    id="inputPuntos"
                                    name="puntos"
                                    placeholder="Ej. 0"
                                    value={formik.values.puntos}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                        </div>

                        <div className="form-group col-md-6">
                            <label htmlFor="inputCodBarra">Codigos de Barra</label>
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupPrepend">
                                    <FontAwesomeIcon icon={faBarcode} />
                                </span>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="inputCodBarra"
                                    name="codigoBarra"
                                    placeholder="Ej. 77555511999"
                                    onKeyPress={handleCodigosBarra}
                                />
                            </div>
                            <ul className="list-group">
                                {codigosBarra.map((cb) => {
                                    return (
                                        <li
                                            id={"cod" + cb.codigo}
                                            key={parseInt(cb.codigo)}
                                            className="list-group-item d-flex justify-content-between align-items-center">
                                            {cb.codigo}
                                            <button
                                                className="btn btn-primary"
                                                onClick={(e) => deleteCodigoBarra(e, cb.codigo)}>
                                                <FontAwesomeIcon icon={faMinus} />
                                            </button>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>

                    <div className="d-flex bd-highlight">
                        <div className="p-2 bd-highlight">
                            <ModalArticulo
                                setCodigoArticulo={setCodigoArticulo}
                                inputTotalRef={inputTotalRef}
                            />
                        </div>
                        <div className="p-2 flex-grow-1 bd-highlight">
                            <input className="form-control form-control-lg"
                                style={{ height: 45 }}
                                type="text"
                                placeholder="Ingrese un articulo"
                                value={codigoArticulo || ''}
                                ref={inputTotalRef}
                                onChange={(e) => setCodigoArticulo(e.target.value)}
                            />
                        </div>
                        <div className="p-2 flex-grow-1 bd-highlight">
                            <input className="form-control form-control-lg"
                                style={{ height: 45 }}
                                type="text"
                                placeholder="cantidad"
                                value={cantidadArticulo}
                                onChange={(e) => setCantidadArticulo(e.target.value)}
                            />
                        </div>
                        <div className="p-2 bd-highlight">
                            <button className="btn btn-outline-success btn-lg"
                                onClick={() => handleKeyPress()}>
                                <FontAwesomeIcon icon={faPlus} /> Añadir
                            </button>
                        </div>
                    </div>

                    <table className="table">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Producto</th>
                                <th scope="col">Precio</th>
                                <th scope="col">Familia</th>
                                <th scope="col">Cantidad</th>
                                <th scope="col">Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(items) ?
                                items.map((item) => {
                                    return (
                                        <tr key={parseInt(item.id, 10)}>
                                            <th scope="row"><h4>{item.codigo}</h4></th>
                                            <td><h4>{item.articulo}</h4> </td>
                                            <td><h4>{item.costo}</h4> </td>
                                            <td><h4>{item.familia.nombre}</h4> </td>
                                            <td><h4>{item.cantidad}</h4> </td>
                                            <td>
                                                <button className="btn btn-outline-primary"
                                                    onClick={() => deleteItem(item)}>
                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })
                                :
                                null
                            }


                        </tbody>
                    </table>

                    <div className="text-center">
                        <button className="btn btn-outline-success btn-lg"
                            onClick={() => addArticuloSubmit()}>
                            <FontAwesomeIcon icon={faPlus} /> Crear artículo compuesto
                        </button>
                    </div>
                </div>



            </div>
        </Fragment>
    );
}

export default withRouter(AddArticulosCompuestos);