import React, { Fragment, useEffect, useState } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCog, faUserTie } from '@fortawesome/free-solid-svg-icons'

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo';

// route
import { withRouter } from 'react-router-dom';

// services
import { getByIdPropietario } from '../../Services/propietarioService'


const Preferencias = ({ history }) => {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    let rol = token.authorities[0];

    const [isLoad, setIsLoad] = useState(true);
    const [propietario, setPropietario] = useState();

    useEffect(() => {
        if (rol !== 'ROLE_ADMIN') history.goBack();
        if (isLoad) {
            getByIdPropietario(1)
                .then(res => setPropietario(res.data))
                .catch(err => setPropietario([]))
            setIsLoad(false)
        }else{
            setIsLoad(false);
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad]);

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faUserCog}
                        title={'Preferencias'} />
                </div>
                <div className="bg-gris p-4">
                    <div className="p-2 bd-highlight">
                        <button className="btn btn-outline-danger btn-lg btn-block"
                            onClick={() => { history.push('/add-propietario',{propietario: propietario})}}>
                            <FontAwesomeIcon icon={faUserTie} /> Datos de propietario
                        </button>
                    </div>
                </div>

            </div>

        </Fragment>
    );
}

export default withRouter(Preferencias);
