import React, { Fragment, useState } from 'react';

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo'

// redux
import { useDispatch } from 'react-redux';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faKeyboard, faSitemap } from '@fortawesome/free-solid-svg-icons'

// router
import { withRouter } from 'react-router-dom';

// actions
import { AddCargo } from '../../Actions/CargoAction'

// services
import { saveCargo } from '../../Services/cargoService';

function AddCargos({ history }) {

    const [nombre, setNombre] = useState('');
    const [codigo, setCodigo] = useState(0);

    const dispatch = useDispatch();

    const addCargoSubmit = (e) => {
        e.preventDefault();
        // validaciones

        // creamos el cardo
        let cargo = {
            nombre: nombre,
            codigo: codigo,
            activo: true
        }

        saveCargo(cargo)
            .then(res => {
                dispatch(AddCargo(res));
                history.goBack();
            })
            .catch(error => console.log('error al guardar'));
    }

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faSitemap}
                        title={'Cargos'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={addCargoSubmit}>
                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputNombre">Nombre</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faSitemap} />
                                    </span>
                                    <input type="text"
                                        id="inputNombre"
                                        name="nombre"
                                        className="form-control"
                                        placeholder="Ej. Gerente, Cocinero, Cajero..."
                                        onChange={e => setNombre(e.target.value)} />
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputCodigo">Codigo</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faKeyboard} />
                                    </span>
                                    <input type="text"
                                        className="form-control"
                                        id="inputCodigo"
                                        name="codigo"
                                        placeholder="Ej. 1, 2, 100"
                                        onChange={e => setCodigo(e.target.value)} />
                                </div>
                            </div>
                        </div>


                        <div className="text-center">
                            <button type="submit" className="btn btn-outline-success">
                                <FontAwesomeIcon icon={faPlus} /> Crear Cargo</button>
                        </div>


                    </form>
                </div>



            </div>
        </Fragment>
    );
}

export default withRouter(AddCargos);