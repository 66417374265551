import React from "react";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

// routes
import { withRouter } from "react-router-dom";

// Sweet alert
import Swal from "sweetalert2";

// services
import { deleteByIdCliente } from "../../Services/clienteService";

const ItemCliente = ({ cliente, history, setIsLoad }) => {
  const EliminarClienteSubmit = (id) => {
    Swal.fire({
      title: "Estas seguro de eliminar?",
      text: "Un cliente eliminado no se puede recuperar!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        // consulta a la api para eliminar
        deleteByIdCliente(id)
          .then((res) => {
            Swal.fire("Eliminado!", "El cliente fue eliminado.", "success");
            setIsLoad(true);
          })
          .catch((error) => {
            Swal.fire({
              type: "error",
              title: "Error",
              text: "Hubo un error vuelve a intentar!",
            });
          });
      }
    });
  };

  return (
    <tr>
      <th scope="row">{cliente.id.persona.id}</th>
      <td>{cliente.id.persona.nombre}</td>
      <td>{cliente.id.persona.apellido}</td>
      <td>{cliente.id.persona.dni}</td>
      <td>{cliente.id.persona.cuilCuit}</td>
      <td>{cliente.condicionFiscal.nombre}</td>
      <td>{cliente.listaPrecio.nombre}</td>
      <td>{cliente.descuento}%</td>
      <td>{cliente.id.persona.correo}</td>
      <td>{cliente.id.persona.telefono}</td>
      <td>{cliente.puntaje}</td>
      <td>
        <button
          className="btn text-danger"
          onClick={() => {
            history.push(`/edit-cliente/${cliente.id}`, { cliente: cliente });
          }}
        >
          <FontAwesomeIcon icon={faEdit} /> Editar
        </button>
        <button
          className="btn text-primary"
          onClick={() => {
            EliminarClienteSubmit(cliente.id.persona.id);
          }}
        >
          <FontAwesomeIcon icon={faTrash} /> Eliminar
        </button>
      </td>
    </tr>
  );
};

export default withRouter(ItemCliente);
