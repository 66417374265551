import React from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

// routes
import { withRouter } from 'react-router-dom';

// Sweet alert
import Swal from 'sweetalert2';

// services
import { deleteByIdComprobante } from '../../Services/comprobanteService';

const ItemComprobante = ({ comprobante, history, setIsLoad, deleteComprobante }) => {

    const activo = (estado) => {
        if (estado === true) return <p className="text-success">Activo</p>;
        else return <p className="text-primary">No Activo</p>;
    }

    const EliminarComprobanteSubmit = (id) => {
        Swal.fire({
            title: 'Estas seguro de eliminar?',
            text: "Un comprobante eliminado no se puede recuperar!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Eliminar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                // consulta a la api para eliminar
                deleteByIdComprobante(id)
                    .then(res => {
                        Swal.fire(
                            'Eliminado!',
                            'El comprobante fue eliminado.',
                            'success'
                        );
                        // INVOCAMOS EL ACTION
                        deleteComprobante(id);
                        setIsLoad(true);
                    })
                    .catch(error => {
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Hubo un error vuelve a intentar!'
                        });
                        console.log(error.response);
                    })
            }
        })
    }

    return (
        <tr>
            <th scope="row">{comprobante.idTipoComprobante}</th>
            <td>{comprobante.nombre}</td>
            <td>{comprobante.codigo}</td>
            <td>{activo(comprobante.activo)}</td>
            <td>
                <button className="btn text-danger"
                    onClick={() => { history.push(`/edit-comprobante/${comprobante.idTipoComprobante}`) }}>
                    <FontAwesomeIcon icon={faEdit} /> Editar
                </button>
                <button className="btn text-primary"
                    onClick={() => { EliminarComprobanteSubmit(comprobante.idTipoComprobante) }}>
                    <FontAwesomeIcon icon={faTrash} /> Eliminar
                </button>
            </td>
        </tr>
    );
}

export default withRouter(ItemComprobante);