import axios from 'axios';
import {url} from './global';

// GET ALL EMPLEADOS PAGINATION
export const getAllEmpleadosPagination = async (page, data) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.post(`${url}/empleados/page/${page}`, data,{headers});
    return res;
}
// END

// GET ALL EMPLEADOS PAGINATION BY SUCURSAL
export const getAllEmpleadosPaginationBySucursal = async (page, data) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.post(`${url}/empleados/sucursal/${page}`, data,{headers});
    return res;
}
// END

export const getByIdEmpleado = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/empleados/${id}`,{headers});
    return res;
}

export const editByIdEmpleado = async (empleado) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.put(`${url}/v2/empleados/${empleado.id}`, empleado,{headers});
    return res;
}

export const deleteByIdEmpleado = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.delete(`${url}/v2/empleados/${id}`,{headers});
    return res;
}

export const saveEmpleado = async (empleado) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/v2/empleados`,empleado,{headers});
    console.log(res);
    return res;
}

export const getAllEmpleados = async (activo, page, nombre, size = 50) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(token),
    };
    let res = await axios.get(
      `${url}/v2/empleados?${
        nombre ? `nombre=${nombre}&` : ""
      }activo=${activo}&page=${page}&size=${size}`,
      { headers }
    );
    return res;
  };

export const getAllEmpleadosBySucursal = async (sucursal) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/empleados/sucursal/${sucursal}`,{headers});
    return res;
}

export const recoverEmpleadoDeleted = async (id) => {
    let token = sessionStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(token),
    };
    let res = await axios.put(
      `${url}/v2/empleados/${id}/recover`,
      {},
      { headers }
    );
    return res;
  };