import axios from 'axios';
import {url} from './global';

export const sendEmail = async (data) => {
    
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    
    let res = await axios.post(`${url}/correo-electronico`,data,{headers});
    return res;
}

export const sendEmailFile = async (data) => {
    
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "multipart/file",
        "Authorization" : "Bearer ".concat(token)
    }
    
    let res = await axios.post(`${url}/correo-electronico/multimedia`,data,{headers});
    return res;
}