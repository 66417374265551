import React from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

// routes
import { withRouter } from 'react-router-dom';

// services
import { deleteByIdCargo } from '../../Services/cargoService';

// Sweet alert
import Swal from 'sweetalert2';

const ItemCargo= ({ cargo, history, eliminarCargo, setIsLoad }) => {


    const eliminarCargoSubmit = (id) => {
        Swal.fire({
            title: 'Estas seguro de eliminar?',
            text: "Un cargo eliminado no se puede recuperar!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Eliminar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                // consulta a la api para eliminar
                deleteByIdCargo(id)
                    .then(res => {
                        Swal.fire(
                            'Eliminado!',
                            'El cargo fue eliminado.',
                            'success'
                        );
                        eliminarCargo(id);
                        setIsLoad(true);
                    })
                    .catch(error => {
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Hubo un error vuelve a intentar!'
                        })
                    })
            }
        })
    }

    return (
        <tr key={parseInt(cargo.idCargo)}>
            <th scope="row">{cargo.idCargo}</th>
            <td>{cargo.nombre}</td>
            <td>{cargo.codigo}</td>
            <td>
                <button className="btn text-danger"
                    onClick={() => { history.push(`/edit-cargo/${cargo.idCargo}`) }}>
                    <FontAwesomeIcon icon={faEdit} /> Editar
                </button>
                <button className="btn text-primary"
                    onClick={() => { eliminarCargoSubmit(cargo.idCargo) }}>
                    <FontAwesomeIcon icon={faTrash} /> Eliminar
                </button>
            </td>
        </tr>
    );
}

export default withRouter(ItemCargo);