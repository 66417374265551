import React, { Fragment, Component } from 'react';

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo'


// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileInvoiceDollar, faEdit, faKeyboard, faUnlockAlt } from '@fortawesome/free-solid-svg-icons'

// router
import { connect } from 'react-redux';

// actions
import { GetComprobante, EditarComprobante } from '../../Actions/ComprobanteAction'

// services
import { getByIdComprobante, editByIdComprobante } from '../../Services/comprobanteService';

class EditarTipoComprobante extends Component {

    state = {
        nombre: '',
        codigo: '',
        activo: '',
        error: false
    }

    componentDidMount() {
        const { id } = this.props.match.params;

        getByIdComprobante(id)
            .then(res => {
                this.props.getComprobante(res);
                const { nombre, codigo, activo } = this.props.comprobante;
                this.setState({ nombre, codigo, activo });
            })
            .catch(err => console.log(err));

    }

    handleComprobante = e => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    editarComprobanteSubmit = (e) => {
        e.preventDefault();

        // destructuring
        const { nombre, codigo, activo } = this.state;
        const { id } = this.props.match.params;

        // validations
        if (nombre === '' || codigo === '') {
            this.setState({ error: true });
            return;
        }

        // creo el departamento actualizado
        const comprobanteUpdated = {
            idTipoComprobante: parseInt(id, 10), nombre, codigo, activo
        }

        editByIdComprobante(comprobanteUpdated)
            .then(res => {
                this.props.editarComprobante(res);
                this.setState({ error: false, nombre: '', codigo: '', activo: '' });
                this.props.history.goBack();
            })
            .catch(error => {
                console.log(error.response.data);
                this.setState({ error: true });
            });

    }

    render() {

        const { nombre, codigo, activo } = this.state

        return (
            <Fragment>
                <div className="container-fluid mt-3">
                    <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                        <BotonBack />
                        <Subtitulo
                            icon={faFileInvoiceDollar}
                            title={'Tipo Comprobantes'} />
                    </div>

                    <div className="bg-gris p-4">
                        <form onSubmit={this.editarComprobanteSubmit}>
                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <label htmlFor="inputNombre">Nombre</label>
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroupPrepend">
                                            <FontAwesomeIcon icon={faFileInvoiceDollar} />
                                        </span>
                                        <input type="text"
                                            id="inputNombre"
                                            name="nombre"
                                            className="form-control"
                                            placeholder="Ej. Lacteos, Gaseosas..."
                                            defaultValue={nombre}
                                            onChange={this.handleComprobante} />
                                    </div>
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="inputCodigo">Codigo</label>
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroupPrepend">
                                            <FontAwesomeIcon icon={faKeyboard} />
                                        </span>
                                        <input type="text"
                                            className="form-control"
                                            id="inputCodigo"
                                            name="codigo"
                                            placeholder="Ej. 1, 2, 100"
                                            defaultValue={codigo}
                                            onChange={this.handleComprobante}
                                            disabled />
                                    </div>
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="inputActivo">Estado</label>
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroupPrepend">
                                            <FontAwesomeIcon icon={faUnlockAlt} />
                                        </span>
                                        <select id="inputActivo" className="custom-select" name="activo"
                                            value={activo} onChange={this.handleComprobante}>
                                            <option value={true}>Activo</option>
                                            <option value={false}>No Activo</option>
                                        </select>
                                    </div>
                                </div>
                            </div>


                            <div className="text-center">
                                <button type="submit" className="btn btn-outline-danger">
                                    <FontAwesomeIcon icon={faEdit} /> Editar Comprobante</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    comprobante: state.comprobantes.comprobante
})

const mapDispatchToProps = dispatch => {
    return {
        getComprobante: (id) => dispatch(GetComprobante(id)),
        editarComprobante: (comprobante) => dispatch(EditarComprobante(comprobante))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditarTipoComprobante);