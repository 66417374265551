import React, { Fragment, useEffect, useState } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCog, faSave } from '@fortawesome/free-solid-svg-icons'

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo';

// route
import { withRouter } from 'react-router-dom';

// services
import { savePropietario, editByIdPropietario } from '../../Services/propietarioService'

// formik
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Swal from 'sweetalert2';

const Propietario = ({ history,location }) => {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    let rol = token.authorities[0];

    const [isLoad, setIsLoad] = useState(true);
    const propietario = location.state.propietario;

    useEffect(() => {
        if (rol !== 'ROLE_ADMIN') history.goBack();
        if (isLoad) {            
            setIsLoad(false)
        }else{
            setIsLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad]);

    const formik = useFormik({
        initialValues: ({
            nombreNegocio: propietario.nombreNegocio ? propietario.nombreNegocio : '',
            cuitCuil: propietario.cuitCuil ? propietario.cuitCuil : '',
            logoNegocio: propietario.logoNegocio ? propietario.logoNegocio : ''
        }),
        validationSchema: Yup.object({
            nombreNegocio: Yup.string().required("Campo obligatorio")
        }),
        onSubmit: values => changePropietario(values)   
    })

    const changePropietario = (values) => {
        if (propietario.idPropietario !== undefined){

            let propietarioEdit = {
                ...values, idPropietario: propietario.idPropietario
            }
    
            editByIdPropietario(propietarioEdit)
                .then( res => { history.goBack()} )
                .catch( error => {
                    Swal.fire({
                        text: "Error al editar, verifique posibles errores.",
                        type: 'error',
                        showConfirmButton: false,
                        timer: 1500
                    });
                    console.log(error)
                })

        }else{

            let propietarioNew = {
                ...values
            }
    
            savePropietario(propietarioNew)
                .then( res => { history.goBack()} )
                .catch( error => {
                    Swal.fire({
                        text: "Error al guardar, verifique por posibles errores.",
                        type: 'error',
                        showConfirmButton: false,
                        timer: 1500
                    });
                    console.log(error)
                })
        }


    }

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faUserCog}
                        title={'Preferencias'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="inputNombreNegocio">Nombre del negocio</label>
                                <div className="input-group-prepend">
                                    <input type="text"
                                        id="inputNombreNegocio"
                                        name="nombreNegocio"
                                        className="form-control"
                                        value={formik.values.nombreNegocio}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        
                                    />
                                </div>
                                {formik.touched.nombreNegocio && formik.errors.nombreNegocio ?
                                    <small className="form-text text-danger">
                                        {formik.errors.nombreNegocio}
                                    </small>
                                    : null}
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="inputCuitCuil">Cuit / Cuil</label>
                                <div className="input-group-prepend">
                                    <input type="text"
                                        id="inputCuitCuil"
                                        name="cuitCuil"
                                        className="form-control"
                                        value={formik.values.cuitCuil}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} />
                                </div>
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="inputLogoNegocio">Logo del negocio</label>
                                <div className="input-group-prepend">
                                    <input type="file"
                                        disabled={true}
                                        id="inputLogoNegocio"
                                        name="logoNegocio"
                                        className="form-control"
                                        value={formik.values.logoNegocio}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} />
                                </div>
                            </div>

                        </div>

                        <div className="text-center">
                            <button type="submit" className="btn btn-outline-success">
                                <FontAwesomeIcon icon={faSave} /> Guardar datos
                            </button>
                        </div>
                    </form>
                </div>

            </div>

        </Fragment>
    );
}

export default withRouter(Propietario);
