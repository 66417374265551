import React, { Fragment, useEffect, useState } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faBuilding, faTrash, faSearch } from '@fortawesome/free-solid-svg-icons'

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo';
import ItemSucursal from './ItemSucursal';

// route
import { withRouter } from 'react-router-dom';

// services
import { getAllSucursales } from '../../Services/sucursalService';
import Swal from 'sweetalert2';
import Header from '../Header/Header';


const Sucursales = ({ history }) => {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    let rol = token.authorities[0];

    const [isLoad, setIsLoad] = useState(true);
    const [sucursales, setSucursales] = useState([]);

    //Pagination
    const [page, setPage] = useState(0);
    const [totalRegister, setTotalRegister] = useState(0);
    const [totalPage, setTotalPage] = useState(0);

    useEffect(() => {
        if (rol !== 'ROLE_ADMIN_MAGENTA') history.goBack();
        if (isLoad) {
            getAllSucursales(true, 0)
                .then(res => {
                    setSucursales(res.data.content);
                    setTotalRegister(res.data.totalElements);
                    setTotalPage(res.data.totalPages);
                    setIsLoad(false);
                })
                .catch(err => console.log('error'));
        } else {
            setIsLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad]);

    const goToDeleted = () => {
        history.push("/sucursales-eliminadas");
    };

    const nextPage = () => {
        if (totalPage === 0) {
            Swal.fire({
                toast: true,
                position: "top",
                title: "No hay mas páginas",
                type: "info",
                showConfirmButton: false,
                timer: 1000,
            });
            return;
        }
        if (page === totalPage - 1) {
            Swal.fire({
                toast: true,
                position: "top",
                title: "No hay mas páginas",
                type: "info",
                showConfirmButton: false,
                timer: 1000,
            });
            return;
        }
        getAllSucursales(true, page + 1)
            .then((res) => {
                setPage(page + 1);
                setSucursales(res.data.content);
                setTotalRegister(res.data.totalElements);
                setTotalPage(res.data.totalPages);
                setIsLoad(false);
            })
            .catch((err) => console.log("error"));
    };

    const previousPage = () => {
        if (page === 0) {
            Swal.fire({
                toast: true,
                position: "top",
                title: "No hay mas páginas",
                type: "info",
                showConfirmButton: false,
                timer: 1000,
            });
            return;
        }
        getAllSucursales(true, page - 1)
            .then((res) => {
                setPage(page - 1);
                setSucursales(res.data.content);
                setTotalRegister(res.data.totalElements);
                setTotalPage(res.data.totalPages);
                setIsLoad(false);
            })
            .catch((err) => console.log("error"));
    };

    const handleBuscador = (e) => {
        if (e.key === "Enter") {
            let data = e.target.value;
            getAllSucursales(true, 0, data)
                .then((res) => {
                    setPage(0);
                    setSucursales(res.data.content);
                    setTotalRegister(res.data.totalElements);
                    setTotalPage(res.data.totalPages);
                    setIsLoad(false);
                })
                .catch((err) => console.log("error"));
        }
    };

    return (
        <Fragment>
            <Header/>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo icon={faBuilding} title={"Sucursales"} />
                </div>

                <div className="d-flex bg-gris p-3 align-items-center justify-content-between">
                    <div>
                        <button
                            className="btn btn-outline-success btn-lg mr-2"
                            onClick={() => {
                                history.push("/add-sucursal");
                            }}
                        >
                            <FontAwesomeIcon icon={faPlus} /> Crear
                        </button>
                        <button
                            className="btn btn-outline-danger btn-lg mr-2"
                            onClick={goToDeleted}
                        >
                            <FontAwesomeIcon icon={faTrash} /> Eliminados
                        </button>
                    </div>
                    <div className="p-2 flex-grow-1 bd-highlight">
                        <div className="input-group-prepend input-group-lg">
                            <span className="input-group-text" id="inputGroupPrepend">
                                <FontAwesomeIcon icon={faSearch} />
                            </span>
                            <input
                                type="text"
                                className="form-control form-lg"
                                id="inputNombre"
                                name="precio"
                                placeholder="Buscar"
                                onKeyPress={(e) => handleBuscador(e)}
                            />
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-row bd-highlight">
                    <div className="p-2 bd-highlight">
                        <button
                            className="btn btn-outline-info"
                            onClick={() => previousPage()}
                            aria-label="Previous"
                        >
                            <span aria-hidden="true">&laquo; Anterior</span>
                        </button>
                    </div>
                    <div className="p-2 bd-highlight mt-1">
                        Registros: {totalRegister} | Pagina actual: {page + 1} | Total
                        páginas : {totalPage}
                    </div>
                    <div className="p-2 bd-highlight">
                        <button
                            className="btn btn-outline-info"
                            onClick={() => nextPage()}
                            aria-label="Next"
                        >
                            <span aria-hidden="true">Siguiente &raquo;</span>
                        </button>
                    </div>
                </div>

                <table className="table table-striped mt-3 text-center">
                    <thead>
                        <tr>
                            <th scope="col">Id</th>
                            <th scope="col">Nombre</th>
                            <th scope="col">Direccion</th>
                            <th scope="col">Telefono</th>
                            <th scope="col">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>

                        {sucursales.map((sucursal) => (
                            <ItemSucursal
                                key={parseInt(sucursal.id)}
                                sucursal={sucursal}
                                setIsLoad={setIsLoad} />
                        ))}

                    </tbody>
                </table>
            </div>
        </Fragment>
    );
}

export default withRouter(Sucursales);