import React from 'react';

// icons
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faMoneyBill } from '@fortawesome/free-solid-svg-icons'

// routes
import { withRouter } from 'react-router-dom';

// modals
import ModalDetalleCompra from './ModalDetalleCompra/ModalDetalleCompra';
import ModalDetallePago from './ModalDetallePagos/ModalDetallePago';
import { faTrash } from '@fortawesome/free-solid-svg-icons';


// services
import { deleteByIdCompra } from '../../Services/compraService';

// Sweet alert
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ItemCompras = ({ compra, setIsLoad }) => {

    const estado = (estado) => {
        if (estado === 'ADEUDADA') return <p className="text-primary">ADEUDADA</p>;
        if (estado === 'PAGADA_PARCIALMENTE') return <p className="text-warning">PAGADA PARCIALMENTE</p>;
        if (estado === 'PAGADA') return <p className="text-success">PAGADA</p>;
    }

    const EliminarCompraSubmit = (compra) => {
        Swal.fire({
            title: "Estas seguro de eliminar?",
            text: "Se eliminara el item!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, Eliminar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.value) {
                // consulta a la api para eliminar
                deleteByIdCompra(compra.id)
                    .then((res) => {
                        Swal.fire("Eliminado!", "La compra fue eliminada.", "success");
                        setIsLoad(true);
                    })
                    .catch((error) => {
                        Swal.fire({
                            type: "error",
                            title: "Error",
                            text: "Hubo un error vuelve a intentar!",
                        });
                    });
            }
        });
    };

    return (
        <tr>
            <th scope="row">{compra.id}</th>
            <td>{compra.proveedor.nombre}</td>
            <td>${(compra.total).toFixed(2)}</td>
            <td>{estado(compra.estado)}</td>
            <td>{(compra.fecha).split('T')[0]}</td>
            <td>{compra.sucursal.nombre}</td>
            <td>{compra.tipoComprobante.nombre}</td>
            <td>{compra.numeroComprobante}</td>
            <td>
                <ModalDetallePago item={compra} />
                <ModalDetalleCompra item={compra} />
                <button
                    className="btn text-primary"
                    onClick={() => {
                        EliminarCompraSubmit(compra);
                    }}
                >
                    <FontAwesomeIcon icon={faTrash} /> Eliminar
                </button>
            </td>
        </tr>
    );
}

export default withRouter(ItemCompras);