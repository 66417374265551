import React, { Fragment, useEffect, useState } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons'

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo';
import ItemComprobante from './ItemComprobante';

// redux
import { useSelector, useDispatch } from 'react-redux'

// actions
import { GetComprobantes, DeleteComprobante } from '../../Actions/ComprobanteAction';

// route
import { withRouter } from 'react-router-dom';

// services
import { getAllComprobantes } from '../../Services/comprobanteService';

const TipoComprobantes = ({ history }) => {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    let rol = token.authorities[0];

    const [isLoad, setIsLoad] = useState(true);
    const comprobantes = useSelector(state => state.comprobantes.comprobantes);
    const dispatch = useDispatch();
    const deleteComprobante = (id) => {dispatch(DeleteComprobante(id))}; 

    useEffect(() => {   
        if (rol !== 'ROLE_ADMIN') history.goBack();
        if (isLoad) {
            getAllComprobantes()
                .then(res => {
                    dispatch(GetComprobantes(res));
                    setIsLoad(false);
                })
                .catch(err => console.log('error'));
        } else {
            setIsLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad]);

    return (
        <Fragment>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faFileInvoiceDollar}
                        title={'Tipos Comprobantes'} />
                </div>

                <div className="bg-gris p-3">
                    <button className="btn btn-outline-success btn-lg ml-2"
                        onClick={() => { history.push('/add-comprobante') }}>
                        <FontAwesomeIcon icon={faPlus} /> Crear
                    </button>

                    <table className="table table-striped mt-3 text-center">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Codigo</th>
                                <th scope="col">Estado</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>

                            {comprobantes.map((comprobante, index) => (
                                <ItemComprobante
                                    key={index}
                                    comprobante={comprobante}
                                    deleteComprobante={deleteComprobante}
                                    setIsLoad={setIsLoad} />
                            ))}

                        </tbody>
                    </table>

                </div>

            </div>
        </Fragment>
    );
}

export default withRouter(TipoComprobantes);