import React from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

// services
import { recoverFamiliaDeleted } from '../../Services/familiaService';

// Sweet alert
import Swal from 'sweetalert2';

// router
import { withRouter } from 'react-router-dom';

const ItemFamiliaEliminada = ({ familia, setIsLoad }) => {

    const recoverFamiliaSubmit= (familia) => {
        Swal.fire({
            title: 'Estas seguro de recuperarlo?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Recuperar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                // consulta a la api para eliminar
                familia.activo = false;
                recoverFamiliaDeleted(familia.id)
                    .then(res => {
                        Swal.fire(
                            'Recuperado!',
                            'La familia fue recuperada.',
                            'success'
                        );
                        setIsLoad(true);
                    })
                    .catch(error => {
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Hubo un error vuelve a intentar!'
                        });
                        console.log(error.response);
                    })
            }
        })
    }


    return (
        <tr>
            <th scope="row">{familia.id}</th>
            <td>{familia.nombre}</td>
            <td>{familia.ganancia}</td>
            <td>
                <button className="btn text-warning"
                        onClick={() => { recoverFamiliaSubmit(familia)}}>
                    <FontAwesomeIcon icon={faTrash}/> Recuperar
                </button>
            </td>
        </tr>
    );
}

export default withRouter(ItemFamiliaEliminada);