import axios from 'axios';
import { url } from './global';

export const getAllCajas = async () => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/cajas`, { headers });
    return res;
}

export const save = async (caja) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
    }
    let res = await axios.post(`${url}/cajas`, caja, { headers });
    return res;
}

export const update = async (caja) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
    }
    let res = await axios.put(`${url}/cajas/${caja.idCaja}`, caja, { headers });
    return res;
}

// PAGINATION ALL CAJAS
export const findAllCajasPage = async (page, data) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
    }
    let res = await axios.post(`${url}/cajas/page/${page}`, data, { headers });
    return res;
}

// PAGINATION BY SUCURSAL
export const findAllCajasPageBySucursal = async (page, data) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
    }
    let res = await axios.post(`${url}/cajas/sucursal/${page}`, data, { headers });
    return res;
}

export const isCajaActiva = async (puntoVenta) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/isCajaActiva/${puntoVenta}`, { headers });
    return res;
}

// GET CAJA ACTIVA
export const getCajaActivaByPtoVenta = async (puntoVenta) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/cajas/puntoventa/${puntoVenta}`, { headers });
    return res;
}

// GET CAJA WITH ITEMS FINALES
export const getCajaItemFinalByPtoVenta = async (puntoVenta) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/cajas/itemscierre/${puntoVenta}`, { headers });
    return res;
}

// DELETE ITEM CAJA
export const deleteItemCaja = async (caja) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
    }
    let res = await axios.put(`${url}/cajas/eliminaitem/${caja.idCaja}`, caja, { headers });
    return res;
}

// REPORTES CAJAS
export const getCajasReportes = async (sucursal, fechad, fechah) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/cajas/sucursal/${sucursal}/reportes/${fechad}/${fechah}`, { headers });
    return res;
}

