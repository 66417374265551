import React from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faCheck } from '@fortawesome/free-solid-svg-icons'


// router
import { withRouter } from 'react-router-dom';

const ItemClienteModalVenta = ({ cliente, setCliente, setListaPrecio }) => {

    const handleCliente = cliente => {
        setCliente(cliente);
        setListaPrecio(cliente.listaPrecio.idListaPrecio)
    }

    return (
        <tr>
            <th scope="row">{ cliente.idCliente.persona.idPersona }</th>
            <td>{cliente.idCliente.persona.nombre + ', ' + cliente.idCliente.persona.apellido}</td>
            <td>{cliente.idCliente.persona.dni}</td>
            <td>{cliente.condicionFiscal.nombre}</td>
            <td>
                <button className="btn text-danger" data-dismiss="modal"
                        onClick={ () =>{ handleCliente(cliente) }}>
                    <FontAwesomeIcon icon={faCheck}/>
                </button>
            </td>
        </tr>
    );
}

export default withRouter(ItemClienteModalVenta);