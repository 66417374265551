import React from "react";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

// services
import { recoverProveedorDeleted } from "../../Services/proveedorService";

// Sweet alert
import Swal from "sweetalert2";

// router
import { withRouter } from "react-router-dom";

const ItemProveedorEliminado = ({ proveedor, setIsLoad }) => {
  const recoverProveedorSubmit = (proveedor) => {
    Swal.fire({
      title: "Estas seguro de recuperarlo?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Recuperar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        recoverProveedorDeleted(proveedor.id)
          .then((res) => {
            Swal.fire("Recuperado!", "El proveedor fue recuperado.", "success");
            setIsLoad(true);
          })
          .catch((error) => {
            Swal.fire({
              type: "error",
              title: "Error",
              text: "Hubo un error vuelve a intentar!",
            });
            console.log(error.response);
          });
      }
    });
  };

  return (
    <tr>
      <th scope="row">{proveedor.id}</th>
      <td>{proveedor.nombre}</td>
      <td>{proveedor.telefono}</td>
      <td>{proveedor.direccion}</td>
      <td>{proveedor.url}</td>
      <td>
        <button
          className="btn text-warning"
          onClick={() => {
            recoverProveedorSubmit(proveedor);
          }}
        >
          <FontAwesomeIcon icon={faTrash} /> Recuperar
        </button>
      </td>
    </tr>
  );
};

export default withRouter(ItemProveedorEliminado);
