import React, { Fragment, useState } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faMoneyBillWave } from '@fortawesome/free-solid-svg-icons'

// services
import { getPagosByVenta } from '../../../Services/clientePagoService';
import { getPdfHistorialPagosConCC } from '../../../Services/reporteService';

// router
import { withRouter } from 'react-router-dom';

// modals
import ItemDetallePago from './ItemDetallePago';

import Swal from 'sweetalert2';


const ModalDetallePago = ({item}) => {

    const [pagos, setPagos] = useState([]);

    const estado = (estado) => {
        if (estado === 'PAGADA') return <p className="text-success">PAGADA</p>;
        if (estado === 'PAGADA_PARCIALMENTE') return <p className="text-warning">PAGADA PARCIALMENTE</p>;
        if (estado === 'ADEUDADA') return <p className="text-primary">ADEUDADA</p>;
    }

    const getPagos = () => {
        getPagosByVenta(item.idVenta)
            .then(res => {          
                setPagos(res.data);
            
            })
            .catch(res => {
                console.log(res);
            })
    }
 
    const handleImprimirHistorialPagos = async (item) =>{
        try {
            const pdf = await getPdfHistorialPagosConCC(item)
            const contenido = encodeURI(pdf);
            let pdfWindow = window.open("");
            pdfWindow.document.write(
                `<iframe width='100%' height='100%' margin='0px' padding='0px' src='data:application/pdf;base64,${contenido}'>
                </iframe>`
            );            
        } catch (error) {
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'Hubo un error vuelve a intentar!'
            });
        }
    }

    return (
        <Fragment>

            <button type="button" className="btn btn-success"
                data-toggle="modal" data-target={"#modalPagosVenta"+item.idVenta}
                onClick={() => getPagos()}>
                <FontAwesomeIcon icon={faMoneyBillWave}/> Historial de pagos
            </button>

            <div className="modal fade" id={"modalPagosVenta"+item.idVenta} tabIndex="-1" role="dialog" aria-labelledby="modalPagosVentaTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <h5 className="modal-title" id="modalPagosVentaTitle"><b>Detalle pagos</b></h5>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <span className="col-md"><b>Fecha:</b></span>
                                <span className="col-md"><b>Total:</b></span>
                                <span className="col-md"><b>Estado:</b></span>
                            </div>
                            <div className="row">
                                <span className="col-md">{item.fecha.split('T')[0]}</span>
                                <span className="col-md">${(item.total).toFixed(2)}</span>
                                <span className="col-md">{estado(item.estado)}</span>
                            </div>
                            <table className="table table-striped mt-3 text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Medio Pago</th>
                                        <th scope="col">Monto</th>
                                        <th scope="col">Fecha</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pagos.map((item) => (
                                        
                                        <ItemDetallePago
                                            key={parseInt(item.idHistorialCCC)}
                                            item = {item}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={ () => handleImprimirHistorialPagos(pagos)}>Imprimir</button>
                            <button type="button" className="btn btn-primary" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    );

}

export default withRouter(ModalDetallePago);