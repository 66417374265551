import axios from 'axios';
import { url } from './global';

export const saveError = async (body) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
}
    const res = await axios.post(`${url}/v2/errores`,body,{headers});
    return res;
}