import React from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'


// router
import { withRouter } from 'react-router-dom';

const ItemArticuloModal = ({ articulo, setCodigoArticulo }) => {


    const handleSetCodigo = () => {
        setCodigoArticulo(articulo);
    }

    return (
        <tr>
            <th scope="row">{articulo.id}</th>
            <td>{articulo.nombre}</td>
            <td>{articulo.costo}</td>
            <td>{articulo.marca.nombre}</td>
            <td>
                <button className="btn text-danger" data-dismiss="modal"
                    onClick={() => { handleSetCodigo() }}>
                    <FontAwesomeIcon icon={faCheck} />
                </button>
            </td>
        </tr>
    );
}

export default withRouter(ItemArticuloModal);