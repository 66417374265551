import React, { Fragment, useState, useEffect } from 'react';

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo';
import Header from '../Header/Header';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBarcode, faPlus, faDollarSign, faArchive, faCartPlus, faFileAlt,
    faKeyboard, faMinus, faBalanceScale, faPercent, faSortNumericUp
} from '@fortawesome/free-solid-svg-icons'

// services
import { getAllFamilias } from '../../Services/familiaService';
import { getAllMarcas } from '../../Services/marcaService';
import { getAllUnidadMedidas } from '../../Services/unidadMedidaService';
import { saveArticulos } from '../../Services/articuloService';
import { saveError } from "../../Services/errorService";

// routes
import { withRouter } from 'react-router-dom';

// Sweet alert
import Swal from 'sweetalert2';

// formik
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';


function AddArticulo({ history }) {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    let rol = token.authorities[0];

    const [isLoad, setIsLoad] = useState(true);

    const [codigosBarra, setCodigosBarra] = useState([]);
    const [familias, setFamilias] = useState([]);
    const [marcas, setMarcas] = useState([]);
    const [unidadMedidas, setUnidadMedidas] = useState([]);

    const handleCodigosBarra = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            let codigoBarra = { codigo: e.target.value };
            setCodigosBarra([
                ...codigosBarra,
                codigoBarra
            ]);
            e.target.value = '';
        }
    }

    const deleteCodigoBarra = (e, codigo) => {
        e.preventDefault();
        let codigosBarraFilter = codigosBarra.filter(function (cv) {
            return cv.codigo !== codigo;
        });
        setCodigosBarra(codigosBarraFilter);
    }

    useEffect(() => {
        if (rol !== 'ROLE_ADMIN_MAGENTA') history.goBack();
        document.getElementById("inputNombre").focus()
        if (isLoad) {
            getAllFamilias(true, 0, "", 100)
                .then(res => setFamilias(res.data.content))
                .catch(err => console.log('error'));
            getAllMarcas(true, 0, "", 100)
                .then(res => {
                    setMarcas(res.data.content)})
                .catch(err => console.log('error'));
            getAllUnidadMedidas(true, 0, "", 100)
                .then(res => {
                    setUnidadMedidas(res.data.content)})
                .catch(err => console.log('error'));
            setIsLoad(false);
        } else {
            setIsLoad(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // atributos de articulo
    const formik = useFormik({
        initialValues: {
            nombre: '',
            costo: 0.0,
            precioFinal: 0.0,
            puntos: 0,
            codigo: '',
            descripcion: '',
            pesable: false,
            balanza: false,
            pesoPromedio: 0,
            compuesto: false,
            porcentajeGanancia: 0,
            familia: '',
            marca: '',
            unidadMedida: ''
        },
        validationSchema: Yup.object({
            nombre: Yup.string().required("Obligatorio"),
            costo: Yup.number().required("Obligatorio").min(0),
            codigo: Yup.string().matches(/^[0-9]+$/, "Solo números").required("Obligatorio"),
            familia: Yup.object().required("Obligatorio"),
            marca: Yup.object().required("Obligatorio"),
            unidadMedida: Yup.object().required("Obligatorio")
        }),
        onSubmit: values => addArticuloSubmit(values)
    });

    const addArticuloSubmit = ({ nombre, costo, precioFinal, puntos, codigo, descripcion, pesable, balanza,
        pesoPromedio, compuesto, porcentajeGanancia, familia, marca, unidadMedida }) => {

        let articulo = {
            nombre, costo, precioFinal, puntos, codigo, descripcion, pesable, balanza,
            pesoPromedio, compuesto, porcentajeGanancia,
            familiaId: familia.id, marcaId: marca.id, unidadMedidaId: unidadMedida.id, codigosBarra
        }

        saveArticulos(articulo)
            .then(res => {
                history.goBack();
            })
            .catch(async error => {
                const errorData = {
                    "mensaje": "Error al intentar agregar un articulo",
                    "detalle": error.message,
                    "entidad": "ARTICULO",
                    "tipo": "WEBAPP",
                    "severidad": "HIGH",
                    "reportado": "APPLICATION"
                };

                await saveError(errorData);
                Swal.fire({
                    title: "Faltan datos o algunos son incorrectos.",
                    text: error.message,
                    type: 'error',
                    showConfirmButton: false,
                    timer: 2000
                });
            });

    }

    return (
        <Fragment>
            <Header/>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo icon={faBarcode} title={"Articulos"} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={formik.handleSubmit}>

                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="inputNombre">Nombre</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faCartPlus} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputNombre"
                                        name="nombre"
                                        className="form-control"
                                        placeholder="Ej. Lacteos, Gaseosas..."
                                        value={formik.values.nombre}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.nombre && formik.errors.nombre ?
                                    <small className="form-text text-danger">
                                        {formik.errors.nombre}
                                    </small>
                                    : null}
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="inputCosto">Costo</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faDollarSign} />
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        step="any"
                                        id="inputCosto"
                                        name="costo"
                                        placeholder="Ej. 100, 200,..."
                                        value={formik.values.costo}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.costo && formik.errors.costo ?
                                    <small className="form-text text-danger">
                                        {formik.errors.costo}
                                    </small>
                                    : null}
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="inputPrecioFinal">Precio Final</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faDollarSign} />
                                    </span>
                                    <input
                                        type="number"
                                        step="any"
                                        id="inputPrecioFinal"
                                        name="precioFinal"
                                        className="form-control"
                                        placeholder="$250"
                                        value={formik.values.precioFinal}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="inputPGanancia">Porcentaje Ganancia</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faPercent} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputPGanancia"
                                        name="porcentajeGanancia"
                                        className="form-control"
                                        placeholder="10, 20"
                                        value={formik.values.porcentajeGanancia}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="inputPesable">Pesable</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faArchive} />
                                    </span>
                                    <select
                                        id="inputPesable"
                                        className="custom-select"
                                        name="pesable"
                                        value={formik.values.pesable}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}>
                                        <option value={true}>Si</option>
                                        <option value={false}>No</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="inputPesoPromedio">Peso promedio</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faBalanceScale} />
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputPesoPromedio"
                                        name="pesoPromedio"
                                        placeholder="medida en gramos"
                                        value={formik.values.pesoPromedio}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-row">

                            <div className="form-group col-md-4">
                                <label htmlFor="inputBalanza">Balanza</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faBalanceScale} />
                                    </span>
                                    <select
                                        id="inputBalanza"
                                        className="custom-select"
                                        name="balanza"
                                        value={formik.values.balanza}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}>
                                        <option value={true}>Si</option>
                                        <option value={false}>No</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="inputCodProducto">Codigo del producto</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faKeyboard} />
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputCodProducto"
                                        name="codigo"
                                        placeholder="Ej 100"
                                        value={formik.values.codigo}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.codigo && formik.errors.codigo ?
                                    <small className="form-text text-danger">
                                        {formik.errors.codigo}
                                    </small>
                                    : null}
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="inputDescripcion">Descripcion</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faFileAlt} />
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputDescripcion"
                                        name="descripcion"
                                        placeholder="Ej. producto envasado"
                                        value={formik.values.descripcion}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} />
                                </div>
                            </div>
                        </div>

                        <div className="form-row">

                            <div className="form-group col-md-4">
                                <label htmlFor="inputFamilia">Familia</label>
                                <div>
                                    <Select
                                        placeholder={"Seleccionar una familia"}
                                        options={familias}
                                        onChange={value => formik.setFieldValue('familia', value)}
                                        getOptionValue={(values) => values.id}
                                        getOptionLabel={(values) => values.nombre}
                                    >
                                    </Select>
                                </div>
                                {formik.touched.familia && formik.errors.familia ?
                                    <small className="form-text text-danger">
                                        {formik.errors.familia}
                                    </small>
                                    : null}
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="inputMarca">Marca</label>
                                <div>
                                    <Select
                                        placeholder={"Seleccionar una marca"}
                                        options={marcas}
                                        onChange={value => formik.setFieldValue('marca', value)}
                                        getOptionValue={(values) => values.id}
                                        getOptionLabel={(values) => values.nombre}
                                    >
                                    </Select>
                                </div>
                                {formik.touched.marca && formik.errors.marca ?
                                    <small className="form-text text-danger">
                                        {formik.errors.marca}
                                    </small>
                                    : null}
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="inputUnidadMedida">Unidad Medida</label>
                                <div>
                                    <Select
                                        placeholder={"Seleccionar una unidad de medida"}
                                        options={unidadMedidas}
                                        onChange={value => formik.setFieldValue('unidadMedida', value)}
                                        getOptionValue={(values) => values.id}
                                        getOptionLabel={(values) => values.nombre}
                                    >
                                    </Select>
                                </div>
                                {formik.touched.unidadMedida && formik.errors.unidadMedida ?
                                    <small className="form-text text-danger">
                                        {formik.errors.unidadMedida}
                                    </small>
                                    : null}
                            </div>

                        </div>

                        <div className="form-row">
                            
                            <div className="form-group col-md-6">
                                <label htmlFor="inputPuntos">Puntos</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faSortNumericUp} />
                                    </span>
                                    <input
                                        type="number"
                                        className="form-control"
                                        step="any"
                                        id="inputPuntos"
                                        name="puntos"
                                        placeholder="Ej. 0"
                                        value={formik.values.puntos}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputCodBarra">Codigos de Barra</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faBarcode} />
                                    </span>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="inputCodBarra"
                                        name="codigoBarra"
                                        placeholder="Ej. 77555511999"
                                        onKeyPress={handleCodigosBarra}
                                    />
                                </div>
                                <ul className="list-group">
                                    {codigosBarra.map((cb) => {
                                        return (
                                            <li
                                                id={"cod" + cb.codigo}
                                                key={parseInt(cb.codigo)}
                                                className="list-group-item d-flex justify-content-between align-items-center">
                                                {cb.codigo}
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={(e) => deleteCodigoBarra(e, cb.codigo)}>
                                                    <FontAwesomeIcon icon={faMinus} />
                                                </button>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>

                        <div className="text-center">
                            <button type="submit" className="btn btn-outline-success">
                                <FontAwesomeIcon icon={faPlus} /> Crear Articulo</button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    );
}

export default withRouter(AddArticulo);