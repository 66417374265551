import React from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

// Service
import { deleteStockSucursal } from '../../Services/stockSucursalService';

// routes
import { withRouter } from 'react-router-dom';

// Sweer Alert
import Swal from 'sweetalert2';

const ItemStockSucursal = ({ articulo, sucursal, setIsLoad, history }) => {


  const condicionStock = (stock) => {
    if (parseFloat(stock) >= parseFloat(articulo.cantidad)) return <p className="text-success">{articulo.cantidad}</p>;
    else return <p className="text-warning">{Number.parseFloat(articulo.cantidad).toFixed(2)}</p>;
  }

  const EditarStock = () => {
    if ((articulo.articulo.compuesto).toString() === "true") {
      Swal.fire({
        title: 'No se pueden modificar articulos compuestos, su stock depende de otros',
        type: 'info',
        showConfirmButton: false,
        timer: 2000
      });
      return;
    }
    history.push(`/edit-stock-sucursal/${articulo.articulo.id}`, { stockSucursal: articulo })
  }

  const deleteStock = (articulo) => {
    Swal.fire({
      title: 'Estas seguro de eliminar?',
      text: "Se eliminara el articulo de la sucursal!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        deleteStockSucursal(sucursal.id, articulo.articulo.id)
          .then(res => {
            Swal.fire(
              'Eliminado!',
              'El articulo fue eliminado de la sucursal.',
              'success'
            );
            setIsLoad(true);
          })
          .catch(error => {
            Swal.fire({
              type: 'error',
              title: 'Error',
              text: 'Hubo un error vuelve a intentar!'
            });
            console.log(error.response);
          })
      }
    })
  }

  return (
    <tr>
      <th scope="row">{articulo.articulo.id}</th>
      <td>{articulo.articulo.nombre}</td>
      <td>{Number(articulo.articulo.costo).toFixed(2)}</td>
      <td>{Number(articulo.articulo.precioFinal).toFixed(2)}</td>
      <td>{Number(articulo.articulo.precioFinalCalculado).toFixed(2)}</td>
      <td>{Number(articulo.precioFinal).toFixed(2)}</td>
      <td>{(articulo.articulo.pesable) ? 'SI' : 'NO'}</td>
      <td>{articulo.articulo.pesoPromedio}</td>
      <td>{condicionStock(articulo.cantidad)}</td>
      <td>{articulo.articulo.unidadMedida.nombre}</td>
      <td>{Number.parseFloat(articulo.updatedAt) ? articulo.updatedAt.split('T')[0] : '-'}</td>
      <td>
        <button className="btn text-danger" title="Modificar"
          onClick={() => { EditarStock() }}>
          <FontAwesomeIcon icon={faEdit} /> Editar
        </button>
        <button className="btn text-primary" title="Eliminar"
          onClick={() => { deleteStock(articulo) }}>
          <FontAwesomeIcon icon={faTrash} /> Eliminar
        </button>
      </td>
    </tr>
  );
}

export default withRouter(ItemStockSucursal);