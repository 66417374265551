import React, { Fragment, useEffect, useState } from 'react';

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBarcode, faDollarSign, faCartPlus, faFileAlt, faMinus,
    faPercent, faArchive, faPlus,
    faBalanceScale,
    faSortNumericUp,
    faKeyboard
} from '@fortawesome/free-solid-svg-icons'

// router
import { withRouter } from 'react-router-dom';

// services
import { editByIdArticulos } from '../../Services/articuloService';
import { getAllFamilias } from '../../Services/familiaService';
import { getAllMarcas } from '../../Services/marcaService';

// formik
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';

// Swal
import Swal from 'sweetalert2'
import Header from '../Header/Header';
import { getAllUnidadMedidas } from '../../Services/unidadMedidaService';


function EditArticulo({ history, location }) {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    let rol = token.authorities[0];

    const articulo = location.state.articulo;

    const [isLoad, setIsLoad] = useState(true);

    const [codigosBarra, setCodigosBarra] = useState([]);
    const [familias, setFamilias] = useState([]);
    const [marcas, setMarcas] = useState([]);
    const [unidadMedidas, setUnidadMedidas] = useState([]);

    useEffect(() => {
        if (rol !== 'ROLE_ADMIN_MAGENTA') history.goBack();

        if (isLoad) {
            setCodigosBarra(articulo.codigosBarra);
            getAllFamilias(true, 0, "", 100)
                .then(res => setFamilias(res.data.content))
                .catch(err => console.log('error'));
            getAllMarcas(true, 0, "", 100)
                .then(res => {
                    setMarcas(res.data.content)
                })
                .catch(err => console.log('error'));
            getAllUnidadMedidas(true, 0, "", 100)
                .then(res => {
                    setUnidadMedidas(res.data.content)
                })
                .catch(err => console.log('error'));
            setIsLoad(false);
        } else {
            setIsLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // atributos de articulo
    const formik = useFormik({
        initialValues: {
            nombre: articulo.nombre,
            porcentajeGanancia: articulo.porcentajeGanancia,
            costo: articulo.costo,
            pesable: articulo.pesable,
            balanza: articulo.balanza,
            descripcion: articulo.descripcion,
            precioFinal: articulo.precioFinal,
            codigo: articulo.codigo,
            puntos: articulo.puntos,
            pesoPromedio: articulo.pesoPromedio,
            familia: articulo.familia,
            marca: articulo.marca,
            unidadMedida: articulo.unidadMedida
        },
        validationSchema: Yup.object({
            nombre: Yup.string(),
            costo: Yup.number().min(0, "El valor debe ser mayor a 0"),
            precioFinal: Yup.number().min(0, "El valor debe ser mayor a 0"),
            codigo: Yup.string().matches(/^[0-9]+$/, "Solo números"),
            familia: Yup.object().required("Obligatorio"),
            marca: Yup.object().required("Obligatorio"),
            unidadMedida: Yup.object().required("Obligatorio"),
        }),
        onSubmit: values => editArticuloSubmit(values)
    });

    const handleCodigosBarra = (e) => {

        if (e.key === 'Enter') {
            e.preventDefault();
            let codigoBarra = { codigo: e.target.value };
            setCodigosBarra([
                ...codigosBarra,
                codigoBarra
            ]);
            e.target.value = '';
        }
    }

    const deleteCodigoBarra = (e, codigo) => {
        e.preventDefault();
        let codigosBarraFilter = codigosBarra.filter(function (cv) {
            return cv.codigo !== codigo;
        });
        setCodigosBarra(codigosBarraFilter);
    }

    const editArticuloSubmit = ({ nombre, costo, precioFinal, codigo, descripcion, pesable, balanza,
        porcentajeGanancia, puntos, familia, marca, unidadMedida }) => {

        //articulo updated
        let articuloUpdated = {
            id: articulo.id, nombre, costo, precioFinal,
            codigo, descripcion, porcentajeGanancia, puntos, 
            pesable, balanza, codigosBarra, familiaId: familia.id, 
            marcaId: marca.id, unidadMedidaId: unidadMedida.id
        }

        // actualizo el articulo
        editByIdArticulos(articuloUpdated)
            .then(res => {
                // restablecer ref
                history.goBack();
            })
            .catch(error => {
                Swal.fire({
                    text: "Faltan datos o algunos son incorrectos.",
                    type: 'info',
                    showConfirmButton: false,
                    timer: 1500
                });
            });
    }

    return (
        <Fragment>
            <Header />
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo icon={faBarcode} title={'Articulos'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={formik.handleSubmit}>

                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="inputNombre">Nombre</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faCartPlus} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputNombre"
                                        name="nombre"
                                        className="form-control"
                                        placeholder="Ej. Lacteos, Gaseosas..."
                                        value={formik.values.nombre}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.nombre && formik.errors.nombre ?
                                    <small className="form-text text-danger">
                                        {formik.errors.nombre}
                                    </small>
                                    : null}
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="inputCosto">Costo</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faDollarSign} />
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        step="any"
                                        id="inputCosto"
                                        name="costo"
                                        placeholder="Ej. 100, 200,..."
                                        value={formik.values.costo}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.costo && formik.errors.costo ?
                                    <small className="form-text text-danger">
                                        {formik.errors.costo}
                                    </small>
                                    : null}
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="inputPrecioFinal">Precio Final</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faDollarSign} />
                                    </span>
                                    <input
                                        type="number"
                                        step="any"
                                        id="inputPrecioFinal"
                                        name="precioFinal"
                                        className="form-control"
                                        placeholder="$250"
                                        value={formik.values.precioFinal}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.precioFinal && formik.errors.precioFinal ?
                                    <small className="form-text text-danger">
                                        {formik.errors.precioFinal}
                                    </small>
                                    : null}
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="inputPGanancia">Porcentaje Ganancia</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faPercent} />
                                    </span>
                                    <input
                                        type="text"
                                        id="inputPGanancia"
                                        name="porcentajeGanancia"
                                        className="form-control"
                                        placeholder="10, 20"
                                        value={formik.values.porcentajeGanancia}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="inputPesable">Pesable</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faArchive} />
                                    </span>
                                    <select
                                        id="inputPesable"
                                        className="custom-select"
                                        name="pesable"
                                        value={formik.values.pesable}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}>
                                        <option value={true}>Si</option>
                                        <option value={false}>No</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="inputPesoPromedio">Peso promedio</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faBalanceScale} />
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputPesoPromedio"
                                        name="pesoPromedio"
                                        placeholder="medida en gramos"
                                        value={formik.values.pesoPromedio}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-row">

                            <div className="form-group col-md-4">
                                <label htmlFor="inputBalanza">Balanza</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faBalanceScale} />
                                    </span>
                                    <select
                                        id="inputBalanza"
                                        className="custom-select"
                                        name="balanza"
                                        value={formik.values.balanza}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}>
                                        <option value={true}>Si</option>
                                        <option value={false}>No</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="inputCodProducto">Codigo del producto</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faKeyboard} />
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputCodProducto"
                                        name="codigo"
                                        placeholder="Ej 100"
                                        value={formik.values.codigo}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.codigo && formik.errors.codigo ?
                                    <small className="form-text text-danger">
                                        {formik.errors.codigo}
                                    </small>
                                    : null}
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="inputDescripcion">Descripcion</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faFileAlt} />
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="inputDescripcion"
                                        name="descripcion"
                                        placeholder="Ej. producto envasado"
                                        value={formik.values.descripcion}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} />
                                </div>
                            </div>
                        </div>

                        <div className="form-row">

                            <div className="form-group col-md-4">
                                <label htmlFor="inputFamilia">Familia</label>
                                <div>
                                    <Select
                                        placeholder={"Seleccionar una familia"}
                                        options={familias}
                                        defaultValue={formik.values.familia}
                                        onChange={value => formik.setFieldValue('familia', value)}
                                        getOptionValue={(values) => values.id}
                                        getOptionLabel={(values) => values.nombre}
                                    >
                                    </Select>
                                </div>
                                {formik.touched.familia && formik.errors.familia ?
                                    <small className="form-text text-danger">
                                        {formik.errors.familia}
                                    </small>
                                    : null}
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="inputMarca">Marca</label>
                                <div>
                                    <Select
                                        placeholder={"Seleccionar una marca"}
                                        options={marcas}
                                        defaultValue={formik.values.marca}
                                        onChange={value => formik.setFieldValue('marca', value)}
                                        getOptionValue={(values) => values.id}
                                        getOptionLabel={(values) => values.nombre}
                                    >
                                    </Select>
                                </div>
                                {formik.touched.marca && formik.errors.marca ?
                                    <small className="form-text text-danger">
                                        {formik.errors.marca}
                                    </small>
                                    : null}
                            </div>

                            <div className="form-group col-md-4">
                                <label htmlFor="inputUnidadMedida">Unidad Medida</label>
                                <div>
                                    <Select
                                        placeholder={"Seleccionar una unidad de medida"}
                                        options={unidadMedidas}
                                        defaultValue={formik.values.unidadMedida}
                                        onChange={value => formik.setFieldValue('unidadMedida', value)}
                                        getOptionValue={(values) => values.id}
                                        getOptionLabel={(values) => values.nombre}
                                    >
                                    </Select>
                                </div>
                                {formik.touched.unidadMedida && formik.errors.unidadMedida ?
                                    <small className="form-text text-danger">
                                        {formik.errors.unidadMedida}
                                    </small>
                                    : null}
                            </div>

                        </div>

                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputPuntos">Puntos</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faSortNumericUp} />
                                    </span>
                                    <input
                                        type="number"
                                        className="form-control"
                                        step="any"
                                        id="inputPuntos"
                                        name="puntos"
                                        placeholder="Ej. 0"
                                        value={formik.values.puntos}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputCodBarra">Codigos de Barra</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faBarcode} />
                                    </span>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="inputCodBarra"
                                        name="codigoBarra"
                                        placeholder="Ej. 77555511999"
                                        onKeyPress={handleCodigosBarra}
                                    />
                                </div>
                                <ul className="list-group">
                                    {codigosBarra.map((cb) => {
                                        return (
                                            <li
                                                id={"cod" + cb.codigo}
                                                key={parseInt(cb.codigo)}
                                                className="list-group-item d-flex justify-content-between align-items-center">
                                                {cb.codigo}
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={(e) => deleteCodigoBarra(e, cb.codigo)}>
                                                    <FontAwesomeIcon icon={faMinus} />
                                                </button>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>

                        <div className="text-center">
                            <button type="submit" className="btn btn-outline-danger">
                                <FontAwesomeIcon icon={faPlus} /> Editar Articulo</button>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    );

}

export default withRouter(EditArticulo);